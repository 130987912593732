<template>
  <form :id @submit.prevent="handleSubmit"></form>
</template>

<script lang="ts" setup>
/*
 This Adapter is for E2E testing purposes only.
 It simulates the Adyen payment flow by calling the API to get the next action. (like Mbway)
 */

import { getNextAction } from '@backmarket/http-api/src/api-specs-payment/payment/payment'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { sleep } from '@backmarket/utils/async/sleep'

import { getRedirectionToPaymentResult } from '../../../form-common/helpers/getRedirection'
import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterExpose,
  type PaymentFormAdapterProps,
  PaymentSubmitError,
} from '../../../form-common/types'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

defineExpose<PaymentFormAdapterExpose>({
  loadingMessage: 'Waiting for the payment to be processed...',
})

const NEXT_ACTION_RETRY_DELAY = 3000
const FAKE_TIMEOUT = 16 * 60 * 1000

const fetchNextAction = async (paymentId: string) => {
  let cancelled = false

  const nextActionTimeout = setTimeout(() => {
    cancelled = true
  }, FAKE_TIMEOUT)

  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      await sleep(NEXT_ACTION_RETRY_DELAY)

      // eslint-disable-next-line no-await-in-loop
      const payload = await $httpFetch(getNextAction, {
        pathParams: {
          paymentId,
        },
      })

      if (payload.action !== 'RETRY') {
        return payload
      }
    } while (!cancelled)

    throw new Error('FakeAdapter timeout while waiting for the next action')
  } finally {
    clearTimeout(nextActionTimeout)
  }
}

const handleSubmit = async () => {
  emit('submit-start')
  try {
    const { paymentId } = await props.createPayment()

    const nextAction = await fetchNextAction(paymentId)

    if (nextAction.action === 'SHOW_ERROR') {
      emit(
        'submit-error',
        new PaymentSubmitError({
          cause: nextAction,
          readableMessage: {
            title: nextAction.data?.title ?? '',
            description: nextAction.data?.detail ?? '',
          },
        }),
      )
    } else {
      emit('submit-success', {
        paymentId,
        redirection: getRedirectionToPaymentResult(paymentId),
      })
    }
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}
</script>
