import { navigateTo } from '#imports'

import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import type { Location } from '@ds/types/Location'

import type {
  PaymentGetRedirection,
  PaymentPostRedirection,
  PaymentRedirection,
} from '../types/PaymentRedirection'

type UsePaymentRedirectionOptions = {
  paymentResultLocation: (paymentId?: string) => Location
}

/**
 * Use this composable to proceed to a payment redirection.
 *
 * @example
 *
 * const proceedRedirection = usePaymentRedirection()
 * ...
 * proceedRedirection(redirection)
 */
export const usePaymentRedirection = (
  options: UsePaymentRedirectionOptions,
) => {
  const proceedPost = (redirection: PaymentPostRedirection) => {
    const { document } = window
    const form = document.createElement('form')
    try {
      form.method = 'POST'
      form.action = redirection.url

      Object.entries(redirection.data ?? {}).forEach(([name, value]) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      })

      document.body.appendChild(form)

      form.submit()
    } finally {
      form.remove()
    }
  }

  const proceedGet = (redirection: PaymentGetRedirection) => {
    const nuxtLink = toNuxtLink(redirection.link)
    void navigateTo(nuxtLink, {
      // We consider that the link is external as soon as we don't navigate to a Nuxt named route
      external: !(typeof nuxtLink === 'object' && 'name' in nuxtLink),
    })
  }

  return (redirection: PaymentRedirection) => {
    switch (redirection.method) {
      case 'GET': {
        if ('link' in redirection) {
          return proceedGet(redirection)
        }

        switch (redirection.id) {
          case 'payment-result':
            return proceedGet({
              method: 'GET',
              link: options.paymentResultLocation(redirection.paymentId),
            })
          default:
            throw new Error(
              `Unknown redirection id: ${JSON.stringify(redirection)}`,
            )
        }
      }
      case 'POST':
        return proceedPost(redirection)
      default:
        // @ts-expect-error API data may be invalid
        throw new Error(`Unknown redirection type: ${redirection.method}`)
    }
  }
}
