<template>
  <form :id @submit.prevent="handleSubmit"></form>
</template>

<script setup lang="ts">
import {
  postApplePaySession,
  postConfirmHpp,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type {
  ApplePayJS,
  ApplePaySessionConstructor,
} from '../../../../types/apple-pay'
import { getRedirectionToPaymentResult } from '../../../form-common/helpers/getRedirection'
import { isValidApplePayPaymentMethodConfig } from '../../../form-common/helpers/isValidConfig'
import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterExpose,
  type PaymentFormAdapterProps,
  PaymentSubmitError,
  type SubmitSuccessData,
} from '../../../form-common/types'
import { PaymentMethodMisconfiguredError } from '../../../form-common/types/PaymentMethodMisconfiguredError'

import ApplePayButton from './ApplePayButton/ApplePayButton.vue'

defineExpose<PaymentFormAdapterExpose>({
  // We want to show a custom Apple Pay submit button
  ...(window.CSS.supports('-webkit-appearance', '-apple-pay-button')
    ? { submitButton: { is: ApplePayButton } }
    : {}),
})

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const APPLE_PAY_VERSION = 3
const APPLE_PAY_LABEL = 'Back Market'

const createApplePaySession = () => {
  if (props.paymentMethod == null) {
    throw new Error('Payment method is not defined')
  }

  const { countryCode, currency } = props.paymentMethod
  const { config } = props.paymentMethod

  if (!isValidApplePayPaymentMethodConfig(config)) {
    throw new PaymentMethodMisconfiguredError(
      'Invalid Apple Pay payment method configuration',
    )
  }

  // FIXME: Adyen is exporting broken type for ApplePaySession without a constructor,
  // Also, adyen type are overriding our own type
  const session =
    new (window.ApplePaySession as unknown as ApplePaySessionConstructor)(
      APPLE_PAY_VERSION,
      {
        countryCode,
        currencyCode: currency,
        merchantCapabilities: config.merchantCapabilities,
        ...(!isEmpty(config.supportedCountries)
          ? { supportedCountries: config.supportedCountries }
          : {}),
        supportedNetworks: config.supportedNetworks,
        total: {
          amount: props.basePrice.amount,
          label: APPLE_PAY_LABEL,
        },
      },
    )

  function makePayment({
    onAuthorized,
    onValidateMerchant,
  }: {
    onAuthorized: (
      authorizedEvent: ApplePayJS.ApplePayPaymentAuthorizedEvent,
    ) => Promise<void>
    onValidateMerchant: () => Promise<Record<string, unknown>>
  }) {
    return new Promise<void>((resolve, reject) => {
      session.oncancel = () => {
        // Reject with a PaymentSubmitError that does not contain a readable message or a redirection
        // so that the error is invisible to the user
        reject(
          Object.assign(
            new PaymentSubmitError({
              message: 'Apple Pay session has been cancelled',
              type: '/errors/payment/user/cancel-action',
              source: 'FRONT_GENERIC',
            }),
          ),
        )
      }

      session.onvalidatemerchant = () =>
        onValidateMerchant().then(
          (merchantSession) =>
            session.completeMerchantValidation(merchantSession),
          (error) => {
            session.abort()
            reject(error)
          },
        )

      session.onpaymentauthorized = async (authorizedEvent) => {
        try {
          await onAuthorized(authorizedEvent)

          // @ts-expect-error ApplePaySession is not typed correctly
          session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
          resolve()
        } catch (error) {
          // @ts-expect-error ApplePaySession is not typed correctly
          session.completePayment(window.ApplePaySession.STATUS_FAILURE)
          reject(error)
        }
      }

      session.begin()
    })
  }

  return { session, makePayment }
}

const handleSubmit = async () => {
  emit('submit-start')
  try {
    // 1. Create the Apple Pay session (this must be done prior anything, as
    //    is must be done "from a user gesture")
    const { makePayment } = createApplePaySession()

    // 2. Create the Back Market payment
    const { paymentId } = await props.createPayment()

    // 3. Proceed to the payment
    await makePayment({
      onAuthorized: async (authorizedEvent) => {
        await $httpFetch(postConfirmHpp, {
          pathParams: { paymentId },
          body: { gatewayToken: authorizedEvent.payment.token.paymentData },
        })
      },
      onValidateMerchant: async () => {
        const payload = await $httpFetch(postApplePaySession)

        return payload.session
      },
    })

    emit('submit-success', {
      paymentId,
      redirection: getRedirectionToPaymentResult(paymentId),
    } satisfies SubmitSuccessData)
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}
</script>
