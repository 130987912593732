export default {
  numberLabel: {
    id: 'adyen_card_payment_method_number_label',
    defaultMessage: 'Card number',
  },
  dateLabel: {
    id: 'adyen_card_payment_method_date_label',
    defaultMessage: 'Expiration date',
  },
  securityCodeLabel: {
    id: 'adyen_card_payment_method_security_code_label',
    defaultMessage: 'CVV',
  },
  securityCodeHelpButton: {
    id: 'adyen_card_payment_method_security_code_help_button',
    defaultMessage: 'What is the CVV?',
  },
}
