export default {
  lastNameLabel: {
    id: 'input_last_name_label',
    defaultMessage: 'Last name',
  },
  lastNameRequired: {
    id: 'input_last_name_required',
    defaultMessage: 'The last name field is required',
  },
  firstNameLabel: {
    id: 'input_first_name_label',
    defaultMessage: 'First name',
  },
  firstNameRequired: {
    id: 'input_first_name_required',
    defaultMessage: 'The first name field is required',
  },
  emailLabel: {
    id: 'input_email_label',
    defaultMessage: 'Email',
  },
  emailRequired: {
    id: 'input_email_required',
    defaultMessage: 'The email field is required',
  },
  emailInvalid: {
    id: 'input_email_invalid',
    defaultMessage: 'This email seems to be invalid',
  },
  emailKonbiniInvalid: {
    id: 'adyen_konbini_adapter_invalid_email',
    defaultMessage:
      'The "+" sign is not allowed in the email for this payment method',
  },
  phoneLabel: {
    id: 'adyen_konbini_phone_label',
    defaultMessage: 'Phone number',
  },
  phoneRequired: {
    id: 'adyen_konbini_phone_required',
    defaultMessage: 'Phone number is required',
  },
  phoneInvalid: {
    id: 'adyen_konbini_phone_invalid',
    defaultMessage:
      "Hmm, that number doesn't look quite right. Please make sure its format is correct.",
  },
}
