import type { Ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import type { VirtualCardFormState } from '../../form-common/types/VirtualCardFormState'
import { isAdyenFormTouched } from '../helpers/isAdyenFormTouched'
import type { AdyenChangePayload } from '../types/AdyenEventPayload'
import type {
  AdyenVirtualCardDataIds,
  AdyenVirtualCardFieldIds,
} from '../types/AdyenVirtualCardFieldIds'

import translations from './useAdyenCardFormValidation.translations'

export const useAdyenCardFormValidation = (
  lastAdyenChangePayload: Ref<AdyenChangePayload | null>,
  getVirtualForm: () => VirtualCardFormState,
) => {
  const i18n = useI18n()

  return {
    /**
     * Validate the form with the last adyen change payload
     * @returns true if the form is valid
     */
    validateForm: () => {
      const payload = lastAdyenChangePayload.value
      const virtualForm = getVirtualForm()

      if (!isAdyenFormTouched(payload)) {
        virtualForm.number.invalid = true
        virtualForm.expiryDate.invalid = true
        virtualForm.securityCode.invalid = true

        virtualForm.number.error = i18n(translations.numberRequired)
        virtualForm.expiryDate.error = i18n(translations.dateRequired)
        virtualForm.securityCode.error = undefined

        return false
      }

      const isInvalid = (validKey: AdyenVirtualCardDataIds) => {
        return payload?.valid?.[validKey] === false
      }

      const getAdyenI18nError = (fieldName: AdyenVirtualCardFieldIds) => {
        return payload?.errors?.[fieldName]?.errorI18n
      }

      virtualForm.number.invalid = isInvalid('encryptedCardNumber')
      if (virtualForm.number.invalid) {
        virtualForm.number.error =
          getAdyenI18nError('encryptedCardNumber') ??
          i18n(translations.numberInvalid)
      } else {
        virtualForm.number.error = undefined
      }
      virtualForm.expiryDate.invalid =
        isInvalid('encryptedExpiryMonth') || isInvalid('encryptedExpiryYear')
      if (virtualForm.expiryDate.invalid) {
        virtualForm.expiryDate.error =
          getAdyenI18nError('encryptedExpiryDate') ??
          i18n(translations.dateInvalid)
      } else {
        virtualForm.expiryDate.error = undefined
      }

      const adyenSecurityCodeI18nError = getAdyenI18nError(
        'encryptedSecurityCode',
      )

      virtualForm.securityCode.invalid = (() => {
        if (adyenSecurityCodeI18nError) {
          return true
        }

        // Some cards don't need a security code.
        // For those cards, Adyen still returns valid.encryptedSecurityCode===false.
        // In that case if there is no other errors (for date or number) and the form is invalid,
        // we can assume that the security code is missing and show an error.
        return (
          isInvalid('encryptedSecurityCode') &&
          !payload.isValid &&
          !virtualForm.expiryDate.invalid &&
          !virtualForm.number.invalid
        )
      })()

      if (virtualForm.securityCode.invalid) {
        virtualForm.securityCode.error =
          adyenSecurityCodeI18nError ?? i18n(translations.ccvInvalid)
      } else {
        virtualForm.securityCode.error = undefined
      }

      return payload?.isValid
    },
  }
}
