<template>
  <form :id @submit.prevent="handleSubmit"></form>
</template>

<script setup lang="ts">
import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterProps,
  PaymentSubmitError,
  type SubmitSuccessData,
} from '../../../form-common/types'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const handleSubmit = async () => {
  emit('submit-start')
  try {
    const result = await props.createPayment()

    // We know that the API will return a redirectLink,
    // so we can safely cast the result to SubmitSuccessData
    emit('submit-success', result as SubmitSuccessData)
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}
</script>
