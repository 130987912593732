import { HttpApiError } from '@backmarket/http-api'
import type { NextAction } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

type ErrorWithCustomData = HttpApiError & {
  backmarket: {
    nextAction?: NextAction
    paymentId?: string
  }
}

export const isErrorWithCustomData = (
  error: HttpApiError,
): error is ErrorWithCustomData => {
  return 'backmarket' in error
}
