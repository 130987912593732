<template>
  <RevInputText
    :id
    v-model="model"
    autocomplete="cc-name"
    data-cs-mask
    :icon="IconAvatar"
    :label="i18n(translations.label)"
  />
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInputText } from '@ds/components/InputText'
import { IconAvatar } from '@ds/icons/IconAvatar'

import translations from './InputCreditCardName.translations'

withDefaults(defineProps<{ id?: string }>(), {
  id: 'name',
})
const model = defineModel<string>({ required: true })
const i18n = useI18n()
</script>
