import { VirtualCardFieldIds } from '../../form-common/types/VirtualCardFieldIds'
import { ProcessOutExceptionCodes } from '../types/ProcessOutExceptionCodes'

export const PROCESSOUT_VALIDATION_ERROR_TARGETS: Partial<
  Record<ProcessOutExceptionCodes, VirtualCardFieldIds>
> = {
  [ProcessOutExceptionCodes.CARD_INVALID_NAME]: VirtualCardFieldIds.NAME,
  [ProcessOutExceptionCodes.CARD_MISSING_NUMBER]: VirtualCardFieldIds.NUMBER,
  [ProcessOutExceptionCodes.CARD_INVALID_NUMBER]: VirtualCardFieldIds.NUMBER,
  [ProcessOutExceptionCodes.CARD_MISSING_EXPIRY]:
    VirtualCardFieldIds.EXPIRY_DATE,
  [ProcessOutExceptionCodes.CARD_INVALID_EXPIRY_DATE]:
    VirtualCardFieldIds.EXPIRY_DATE,
  [ProcessOutExceptionCodes.CARD_INVALID_DATE]: VirtualCardFieldIds.EXPIRY_DATE,
  [ProcessOutExceptionCodes.CARD_INVALID_EXPIRY_MONTH]:
    VirtualCardFieldIds.EXPIRY_DATE,
  [ProcessOutExceptionCodes.CARD_INVALID_MONTH]:
    VirtualCardFieldIds.EXPIRY_DATE,
  [ProcessOutExceptionCodes.CARD_INVALID_EXPIRY_YEAR]:
    VirtualCardFieldIds.EXPIRY_DATE,
  [ProcessOutExceptionCodes.CARD_INVALID_YEAR]: VirtualCardFieldIds.EXPIRY_DATE,
  [ProcessOutExceptionCodes.CARD_INVALID_CVC]:
    VirtualCardFieldIds.SECURITY_CODE,
  [ProcessOutExceptionCodes.CARD_MISSING_CVC]:
    VirtualCardFieldIds.SECURITY_CODE,
  [ProcessOutExceptionCodes.CARD_FAILED_CVC]: VirtualCardFieldIds.SECURITY_CODE,
}
