export default {
  defaultSetupErrorTitle: {
    id: 'payment_form_default_setup_error_title',
    defaultMessage: 'Payment with {paymentMethod} not available',
  },
  defaultSetupErrorDescription: {
    id: 'payment_form_default_setup_error_description',
    defaultMessage:
      "We're sorry but payment with {paymentMethod} is currently not available. Please try reloading the page. If this does not work, please try another payment method, or try again later. Here is the error message that our secure payment system sent us: {reason}",
  },
  genericNetworkErrorDescription: {
    // TODO: [PAYIN-3693] Clean translations keys
    id: 'processout_own_load_failure',
    defaultMessage:
      'An error occurred while connecting to our payment provider. Please make sure your Internet connection is working, and try again.',
  },
}
