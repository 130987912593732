import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

export const VirtualCardFieldIds = {
  NAME: 'name',
  NUMBER: 'number',
  EXPIRY_DATE: 'expiryDate',
  SECURITY_CODE: 'securityCode',
} as const

/**
 * Ids of common fields in the virtual card form
 */
export type VirtualCardFieldIds = UnionOfPropertyTypes<
  typeof VirtualCardFieldIds
>
