<template>
  <div class="py-24">
    <PaymentChaosBanner />
    <PaymentMethodsList
      v-model="selectedPaymentMethod"
      :disabled="props.disabled || !isPaymentRequired"
      :loading="isLoadingAvailableMethods"
      :payment-groups
    >
      <PaymentFormAdornment
        :base-price
        :payment-group="selectedPaymentMethodGroup"
        :payment-method="selectedPaymentMethod"
      >
        <PaymentMethodFormBase
          ref="selectedPaymentMethodForm"
          :base-price
          :create-payment
          :disabled
          :payment-method="selectedPaymentMethod"
          :trackingData
          @setup-error="onSetupError"
          @submit-error="emit('submit-error', $event)"
          @submit-start="emit('submit-start', $event)"
          @submit-success="emit('submit-success', $event)"
        />
      </PaymentFormAdornment>
    </PaymentMethodsList>

    <PaymentMethodFormBase
      v-if="!isPaymentRequired"
      ref="passThroughForm"
      :base-price
      :create-payment="createNonRequiredPayment"
      :disabled
      :payment-method="null"
      :trackingData
      @setup-error="onSetupError"
      @submit-error="emit('submit-error', $event)"
      @submit-start="emit('submit-start', $event)"
      @submit-success="emit('submit-success', $event)"
    />

    <div class="mt-32 flex justify-end px-24">
      <PaymentSubmitButton
        v-if="formVersion === 'v1'"
        :form="currentPaymentMethodForm"
        :loading="isFormBusy"
      />
      <PaymentSubmitButtonVNext
        v-else
        :form="currentPaymentMethodForm"
        :loading="isFormBusy"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'

import { HttpEvent, type Price } from '@backmarket/http-api'
import { type PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { usePaymentFormVersion } from '../../../form-common/composables/usePaymentFormVersion'
import { getRedirectionToPaymentResult } from '../../../form-common/helpers/getRedirection'
import {
  PaymentSubmitError,
  type SetupErrorEvent,
  type SubmitErrorEvent,
  type SubmitSuccessEvent,
} from '../../../form-common/types'
import type { PaymentFormCreateFunction } from '../../../form-common/types/PaymentFormCreateFunction'
import type { PaymentMethodFormInstance } from '../../../form-common/types/PaymentMethodFormInstance'
import { useAvailablePaymentMethods } from '../../../methods'
import PaymentMethodsList from '../../../methods/components/PaymentMethodsList/PaymentMethodsList.vue'
import { usePaymentMethodGroups } from '../../../methods/composables/usePaymentMethodGroups'
import {
  type TrackingData,
  usePaymentFormEventTracker,
} from '../../composables/usePaymentFormEventTracker'
import PaymentChaosBanner from '../PaymentChaosBanner/PaymentChaosBanner.vue'
import PaymentFormAdornment from '../PaymentFormAdornment/PaymentFormAdornment.vue'
import PaymentMethodFormBase, {
  type PaymentMethodFormBaseEmits,
} from '../PaymentMethodFormBase/PaymentMethodFormBase.vue'
import PaymentSubmitButton from '../PaymentSubmitButton/PaymentSubmitButton.vue'
import PaymentSubmitButtonVNext from '../PaymentSubmitButton/PaymentSubmitButtonVNext.vue'

import { usePaymentNextAction } from './usePaymentNextAction'

const props = withDefaults(
  defineProps<{
    basePrice: Price
    createPayment: PaymentFormCreateFunction
    disabled?: boolean
    paymentMethods: PaymentMethod[]
    resumePaymentId?: string
  }>(),
  {
    disabled: false,
    resumePaymentId: undefined,
  },
)

const emit = defineEmits<
  PaymentMethodFormBaseEmits & {
    /**
     * Emitted when the selected payment method changes.
     * Can be used to sync other parts of the UI depending on the current payment method.
     */
    'update:selectedMethod': [
      modelValue: PaymentMethod | null,
      isSelectedByUser: boolean,
    ]
  }
>()

const formVersion = usePaymentFormVersion()

// Get available payment methods and group them
const { availablePaymentMethods, pending: isLoadingAvailableMethods } =
  useAvailablePaymentMethods(props.paymentMethods)
const paymentGroups = usePaymentMethodGroups(availablePaymentMethods)

// Define which payment method is selected
const defaultPaymentMethod = computed(() => {
  const method = paymentGroups.value[0]?.methods[0]

  return method?.enabled ? method : null
})
const userSelectedPaymentMethodReference = ref<string | null>(null)
const selectedPaymentMethod = computed<PaymentMethod | null>({
  get: () => {
    if (isLoadingAvailableMethods.value) {
      return null
    }

    return userSelectedPaymentMethodReference.value
      ? (availablePaymentMethods.value.find(
          (method) =>
            method.reference === userSelectedPaymentMethodReference.value,
        ) ?? defaultPaymentMethod.value)
      : defaultPaymentMethod.value
  },
  set: (val) => {
    userSelectedPaymentMethodReference.value = val?.reference ?? null
  },
})

const selectedPaymentMethodGroup = computed(() => {
  if (!selectedPaymentMethod.value) {
    return null
  }

  return (
    paymentGroups.value.find(
      (group) => group.id === selectedPaymentMethod.value?.group,
    ) ?? null
  )
})

const createNonRequiredPayment: PaymentFormCreateFunction = (options) => {
  return props.createPayment({
    ...options,
    // For legacy reason the API expect a payment method
    // even when the user uses a full promo code
    method: options.method ?? selectedPaymentMethod.value,
  })
}

// Prepare for tracking
const isPaymentRequired = computed(() => parseFloat(props.basePrice.amount) > 0)
const trackingData: TrackingData = {
  availablePaymentMethods,
  basePrice: () => props.basePrice,
  defaultPaymentMethod,
  isPaymentRequired,
  selectedPaymentMethod,
}
const track = usePaymentFormEventTracker(trackingData)

// Get the current form adapter
const selectedPaymentMethodForm = ref<PaymentMethodFormInstance | null>(null)
const passThroughForm = ref<PaymentMethodFormInstance | null>(null)
const currentPaymentMethodForm = computed(() => {
  return isPaymentRequired.value
    ? selectedPaymentMethodForm.value
    : passThroughForm.value
})

const onSetupError = (error: SetupErrorEvent) => {
  emit('setup-error', error)
  // TODO: [PAYIN-3611] Select the next available payment method on setup error
  selectedPaymentMethod.value = null
}

// Handle resume payment
const { status: paymentNextActionStatus } = usePaymentNextAction(
  toRef(props, 'resumePaymentId'),
  {
    onEvent: (event, context) => {
      switch (event) {
        case HttpEvent.Attempt:
          track.resumeStart()
          emit('submit-start', {
            paymentMethod: selectedPaymentMethod.value,
          })
          break
        case HttpEvent.Success:
          if (context.data?.action === 'SHOW_ERROR') {
            track.resumeError({ data: context.data })
            emit('submit-error', {
              paymentMethod: selectedPaymentMethod.value,
              message: {
                title: context.data.data?.title ?? '',
                description: context.data.data?.detail ?? '',
              },
            } satisfies SubmitErrorEvent)
          } else {
            track.resumeSuccess()
            emit('submit-success', {
              paymentMethod: selectedPaymentMethod.value,
              paymentId: String(props.resumePaymentId),
              redirection: getRedirectionToPaymentResult(props.resumePaymentId),
            } satisfies SubmitSuccessEvent)
          }
          break
        case HttpEvent.Fail:
          // eslint-disable-next-line no-case-declarations
          const error = PaymentSubmitError.fromAnyError(context.error)
          track.resumeError({ error })
          emit('submit-error', {
            paymentMethod: selectedPaymentMethod.value,
            message: error.readableMessage,
            redirection: error.redirection,
            cause: error,
          })
          break
        default:
          break
      }
    },
  },
)

const isFormBusy = computed(
  () =>
    currentPaymentMethodForm.value?.isBusy ||
    paymentNextActionStatus.value === 'pending' ||
    isLoadingAvailableMethods.value,
)

// Track when the form is displayed
watch(isLoadingAvailableMethods, () => {
  // We consider that the form is displayed when the available payment methods are loaded
  // (or failed to load)
  if (!isLoadingAvailableMethods.value) {
    track.formDisplayed()
  }
})

// Track when the selected payment method changes
watch(selectedPaymentMethod, () => {
  const method = selectedPaymentMethod.value

  const isSelectedByUser =
    method?.reference === userSelectedPaymentMethodReference.value

  if (!isSelectedByUser && isLoadingAvailableMethods.value) {
    // Handle the "payment selected (undefined)" log.
    // When a user arrive at the payment page, go back on the shipping page and return on the payment page,
    // we reload the payment method, and the watch is called with no payment method.
    return
  }

  emit('update:selectedMethod', method, isSelectedByUser)

  track(
    'PAYMENT_FORM_METHOD_SELECTED',
    isSelectedByUser
      ? `Payment method selected by user (${method?.bmCode})`
      : `Default payment method selected (${method?.bmCode})`,
    {
      method,
      selectedByUser: !isSelectedByUser,
    },
  )
})
</script>
