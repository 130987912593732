export default {
  label: {
    id: 'form_input_credit_card_name_label',
    defaultMessage: 'Card holder',
  },
  labelName: {
    id: 'form_input_credit_card_name_label_name',
    defaultMessage: 'Name on card',
  },
  required: {
    id: 'form_input_credit_card_name_required',
    defaultMessage: "Card holder's name is required",
  },
  invalid: {
    id: 'form_input_credit_card_name_invalid',
    defaultMessage: 'This card holder seems to be invalid',
  },
}
