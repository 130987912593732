import { defineAsyncComponent } from 'vue'

import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import type { LocalPaymentMethod } from '../../methods/config/methods.type'

import { PAYMENT_FORM_ADAPTERS } from './adapters'

// Lazy load back-office payment methods to prevent loading it on the front-office
const AchAdapter = defineAsyncComponent(
  () => import('../../form-buyback/components/AchAdapter/AchAdapter.vue'),
)
const BacsAdapter = defineAsyncComponent(
  () => import('../../form-buyback/components/BacsAdapter/BacsAdapter.vue'),
)
const LegacyCardAdapter = defineAsyncComponent(
  () =>
    import(
      '../../form-buyback/components/LegacyCardAdapter/LegacyCardAdapter.vue'
    ),
)
const SepaAdapter = defineAsyncComponent(
  () => import('../../form-buyback/components/SepaAdapter/SepaAdapter.vue'),
)

/**
 * Each payment method has a corresponding adapter component that will be used to render the payment form.
 * This function returns the adapter corresponding to the given payment method.
 */
export const getMethodCorrespondingAdapter = (
  method: PaymentMethod | LocalPaymentMethod | null,
) => {
  if (!method) {
    // NOOP adapter
    // To avoid having to check if the component is defined before rendering it
    return () => null
  }

  if ('isLocal' in method) {
    switch (method.reference) {
      case 'ACH':
        return AchAdapter
      case 'SEPA':
        return SepaAdapter
      case 'BACS':
        return BacsAdapter
      case 'LEGACY_CARD':
        return LegacyCardAdapter
      default:
        return () => null
    }
  }

  const { pspCode, bmCode, integrationType } = method

  const type = integrationType ?? 'DEFAULT'

  return (
    PAYMENT_FORM_ADAPTERS[pspCode][bmCode]?.[type] ||
    PAYMENT_FORM_ADAPTERS[pspCode][bmCode]?.DEFAULT ||
    PAYMENT_FORM_ADAPTERS[pspCode].DEFAULT[type] ||
    PAYMENT_FORM_ADAPTERS[pspCode].DEFAULT.DEFAULT
  )
}
