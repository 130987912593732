<!-- eslint-disable vuejs-accessibility/no-static-element-interactions
  @mousedown ensure that the element is clickable as any InputText even outside the iframe.
  The iframe stays accessible without it.
-->
<template>
  <RevInputVirtual
    class="w-full"
    :disabled
    :error="props.error"
    :filled="props.filled"
    :focused="props.focused"
    :has-clear-button="false"
    helper-text-id="process-out-card-adapter"
    :icon="props.icon"
    :invalid="props.invalid"
    :label
    :touched="props.touched"
  >
    <template #default="{ defaultPaddingClass }">
      <div class="flex size-full items-center">
        <div
          class="flex size-full items-center"
          @mousedown="props.onRequestFocus()"
        >
          <div
            class="w-full"
            :class="[defaultPaddingClass]"
            :data-processout-input="getProcessOutId(props.id)"
            data-processout-placeholder=" "
          />
        </div>
        <div v-if="$slots.icon" class="box-content flex items-center">
          <slot name="icon" />
        </div>
      </div>
    </template>
  </RevInputVirtual>
</template>

<script lang="ts" setup>
import type { Component } from 'vue'

import { RevInputVirtual } from '@ds/components/InputVirtual'

import type { VirtualCardFieldProps } from '../../../form-common/types/VirtualCardFormState'
import { getProcessOutId } from '../../composables/useProcessOutForm.utils'

const props = defineProps<
  {
    label: string
    disabled: boolean
    icon?: Component
  } & VirtualCardFieldProps
>()
</script>
