<template>
  <RevForm
    :id
    class="mt-12"
    :initial-values
    :name="props.id"
    :validate
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <div ref="container">
        <slot name="number" />

        <div class="mt-12 flex gap-12">
          <slot name="expiryDate" />

          <slot name="securityCode" />
        </div>

        <div class="mt-12 flex justify-end gap-12">
          <InputCreditCardName
            v-model="values.name"
            class="w-full"
            :disabled="props.disabled"
            :error="errors.name || virtualForm['name'].error"
          />
        </div>

        <div class="mt-12 flex justify-end">
          <RevLink @click.prevent="handleSecurityCodeTextClick">
            {{ i18n(translations.securityCodeHelpButton) }}
          </RevLink>
        </div>

        <CreditCardTestingAssistant
          v-if="config.public.DEV_PAYMENT_ASSISTANT_ENABLED"
          class="mt-12"
          show-values
          @fill="handleFill"
        />

        <CreditCardSecurityCodeModal :name="MODAL_NAME" />
      </div>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { type VNode, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevForm, makeValidate, setFormValues } from '@ds/components/Form'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import type {
  VirtualCardConcreteValue,
  VirtualCardFieldValues,
  VirtualCardFormState,
} from '../types/VirtualCardFormState'

import CreditCardSecurityCodeModal from './CreditCardSecurityCodeModal/CreditCardSecurityCodeModal.vue'
import CreditCardTestingAssistant from './CreditCardTestingAssistant/CreditCardTestingAssistant.vue'
import { InputCreditCardNameValidators } from './InputCreditCardName.utils'
import InputCreditCardName from './InputCreditCardName.vue'
import translations from './VirtualCardForm.translations'

const i18n = useI18n()
const config = useRuntimeConfig()

const props = defineProps<{
  id: string
  disabled: boolean
  virtualForm: VirtualCardFormState
}>()

const emit = defineEmits<{
  submit: [value: VirtualCardConcreteValue]
  fill: [value: VirtualCardFieldValues]
}>()

defineSlots<{
  number(): VNode
  expiryDate(): VNode
  securityCode(): VNode
}>()

const handleSubmit = (values: VirtualCardConcreteValue) => {
  emit('submit', values)
}

const handleFill = (values: VirtualCardFieldValues) => {
  emit('fill', values)
}

const initialValues: VirtualCardConcreteValue = {
  name: '',
}

const validate = makeValidate<VirtualCardConcreteValue>({
  name: InputCreditCardNameValidators(i18n),
})

watch(
  () => props.virtualForm.name.lastAutofillValue,
  (value) => {
    setFormValues(props.id, { name: value })
  },
)

const MODAL_NAME = 'CREDIT_CARD_SECURITY_CODE'
const handleSecurityCodeTextClick = () => {
  openModal(MODAL_NAME)
}
</script>
