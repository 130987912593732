export default {
  chaosTitle: {
    id: 'payment_chaos_banner_info_title',
    defaultMessage: '',
  },
  chaosMessage: {
    id: 'payment_chaos_banner_info_message',
    defaultMessage:
      'Payments are currently unavailable due to an outage, but we’ve got our best people on it, so check back soon!',
  },
}
