<template>
  <div>
    <PaypalBanner
      v-if="isPaypalAdvertisingAvailable && props.paymentMethod"
      :base-price="props.basePrice"
      class="mb-12"
      :payment-method="props.paymentMethod"
    />

    {{ i18n(translations.body) }}
    <br />
    <DefaultRedirectionText v-if="paymentGroup" :group="paymentGroup" />

    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import PaypalBanner from '../../../../advertising/components/PaypalBanner/PaypalBanner.vue'
import { PAYMENT_METHODS_ADVERTISING } from '../../../../advertising/config/advertising'
import type { PaymentFormAdornmentProps } from '../PaymentFormAdornment.type'

import DefaultRedirectionText from './DefaultRedirectionText.vue'
import translations from './PaypalFormAdornment.translations'

const i18n = useI18n()
const props = defineProps<PaymentFormAdornmentProps>()

// In some countries paypal advertising is not available, so we don't show the banner in those cases
const isPaypalAdvertisingAvailable = computed(() => {
  return props.paymentMethod
    ? (PAYMENT_METHODS_ADVERTISING.paypal.isAvailableFor([props.paymentMethod])
        ?.isAvailable ?? false)
    : false
})
</script>
