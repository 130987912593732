import type { I18n } from '@backmarket/nuxt-module-i18n/types'

import type { ProcessOutException } from '../../../types/processout'
import type { PaymentReadableMessage } from '../../form-common/types/PaymentReadableMessage'

import translations from './getExceptionReadableMessage.translations'

export const getExceptionReasonMessage = (
  i18n: I18n,
  error: ProcessOutException,
): string => {
  return (
    (translations[error.code] && i18n(translations[error.code])) ||
    error.message ||
    error.code ||
    String(error)
  )
}

export const getExceptionReadableMessage = (
  i18n: I18n,
  error: ProcessOutException,
): PaymentReadableMessage => {
  const reason = getExceptionReasonMessage(i18n, error)

  return {
    title: i18n(translations.errorTitle),
    description: i18n(translations.errorDescription, { reason }),
  }
}
