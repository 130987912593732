export default {
  waitingMessage: {
    id: 'adyen_mbway_adapter_waiting_message',
    defaultMessage: 'Confirm your payment to the MB Way application',
  },
  phoneLabel: {
    id: 'input_address_phone_label',
    defaultMessage: 'Phone number',
  },
  phoneRequired: {
    id: 'input_address_phone_required',
    defaultMessage: 'Phone number is required',
  },
  phoneInvalid: {
    id: 'input_address_phone_invalid',
    defaultMessage:
      "Hmm, that number doesn't look quite right. Please make sure its format is correct.",
  },
}
