export default {
  title: {
    id: 'credit_card_security_code_modal_title',
    defaultMessage: 'What’s a CVV?',
  },
  description: {
    id: 'credit_card_security_code_modal_description',
    defaultMessage:
      'Usually a three or four-digit number on your card near the signature',
  },
}
