import type {
  CreatePaymentNextAction,
  CreatePaymentNextActionRedirect,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import type { PaymentRedirection } from '../types/PaymentRedirection'

function getRedirectionFromRedirectNextAction(
  nextAction: CreatePaymentNextActionRedirect,
): PaymentRedirection {
  if (nextAction.httpMethod === 'POST') {
    return {
      method: 'POST',
      url: nextAction.url,
      data: nextAction.data,
    }
  }

  return {
    method: 'GET',
    link: {
      type: 'external',
      href: nextAction.url,
    },
  }
}

/**
 * Generate the redirection object to the payment result page
 */
export function getRedirectionToPaymentResult(
  paymentId?: string,
): PaymentRedirection {
  return {
    method: 'GET',
    id: 'payment-result',
    paymentId,
  }
}

/**
 * Redirect to the payment result page OR to the next action url
 */
export function getRedirection(
  nextAction?: CreatePaymentNextAction,
  paymentId?: string,
): PaymentRedirection {
  switch (nextAction?.type) {
    case 'redirect':
      return getRedirectionFromRedirectNextAction(nextAction)
    case 'voucher':
    default:
      return getRedirectionToPaymentResult(paymentId)
  }
}
