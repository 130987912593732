import type { VirtualCardFieldIds } from '../../form-common/types/VirtualCardFieldIds'
import { AdyenVirtualCardFieldIds } from '../types/AdyenVirtualCardFieldIds'

/**
 * Translates a virtual card field id to an Adyen field id
 */
export const getAdyenCardFieldId = (
  field: VirtualCardFieldIds,
): AdyenVirtualCardFieldIds => {
  switch (field) {
    case 'number':
      return AdyenVirtualCardFieldIds.NUMBER
    case 'expiryDate':
      return AdyenVirtualCardFieldIds.DATE
    case 'securityCode':
      return AdyenVirtualCardFieldIds.SECURITY_CODE
    default:
      throw new Error(`Unsupported field: ${field}`)
  }
}
