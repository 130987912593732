/**
 * Sorts a collection of objects by a property value.
 * The order of values is defined by `valuesOrder` collection.
 * Items whose property has unknown value (not in `valuesOrder`) are preserved,
 * but returned at the end of the collection.
 */
export function sortByProperty<TObject, TValue>(
  collection: TObject[],
  propertyGetter: (item: TObject) => TValue | undefined,
  valuesOrder: TValue[],
) {
  function indexOf(item: TObject) {
    const value = propertyGetter(item)

    const index = value ? valuesOrder.indexOf(value) : -1

    return index === -1 ? valuesOrder.length : index
  }

  return collection.sort((itemA, itemB) => indexOf(itemA) - indexOf(itemB))
}
