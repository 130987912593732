import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

export const ProcessOutCardFieldIds = {
  // While the name field is rendered as a regular RevInputText locally, it is
  // still defined here, as SDK may return errors targeting that field.
  NAME: 'name',

  NUMBER: 'cc-number',
  EXPIRY_DATE: 'cc-exp',
  SECURITY_CODE: 'cc-cvc',
} as const

export type ProcessOutCardFieldIds = UnionOfPropertyTypes<
  typeof ProcessOutCardFieldIds
>

export type ProcessOutCardVirtualFieldIds = Exclude<
  ProcessOutCardFieldIds,
  'name'
>
