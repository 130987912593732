export default {
  ccvInvalid: {
    id: 'form_adyen_client_security_code_invalid',
    defaultMessage: 'This CVV is invalid',
  },
  dateInvalid: {
    id: 'form_adyen_client_date_invalid',
    defaultMessage: 'This date is invalid',
  },
  dateRequired: {
    id: 'form_adyen_client_date_required',
    defaultMessage: 'Date is required',
  },
  numberInvalid: {
    id: 'form_adyen_client_number_invalid',
    defaultMessage: 'This card number is invalid',
  },
  numberRequired: {
    id: 'form_adyen_client_number_required',
    defaultMessage: 'Card number is required',
  },
}
