<template>
  <div ref="container">
    <VirtualCardForm
      :id
      :disabled="props.disabled"
      :virtual-form
      @submit="handleSubmit"
    >
      <template #number>
        <ProcessOutInput
          :disabled="props.disabled"
          :label="i18n(translations.numberLabel)"
          v-bind="virtualForm['number']"
        >
          <template #icon>
            <PaymentNetworkRadioGroup
              v-model="selectedNetwork"
              :disabled="props.disabled"
              :networks="availableNetworks"
              :preferred-networks
            />
          </template>
        </ProcessOutInput>
      </template>

      <template #expiryDate>
        <ProcessOutInput
          :disabled="props.disabled"
          :icon="IconCalendar"
          :label="i18n(translations.dateLabel)"
          v-bind="virtualForm['expiryDate']"
        />
      </template>

      <template #securityCode>
        <ProcessOutInput
          :disabled="props.disabled"
          :icon="IconLockLocked"
          input-id="cc-cvc"
          :label="i18n(translations.securityCodeLabel)"
          v-bind="virtualForm['securityCode']"
        />
      </template>
    </VirtualCardForm>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { postConfirmCard } from '@backmarket/http-api/src/api-specs-payment/payment/payment'
import type { PaymentNetwork } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { IconCalendar } from '@ds/icons/IconCalendar'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import PaymentNetworkRadioGroup from '../../../form-common/components/PaymentNetworkRadioGroup/PaymentNetworkRadioGroup.vue'
import VirtualCardForm from '../../../form-common/components/VirtualCardForm.vue'
import { getRedirectionToPaymentResult } from '../../../form-common/helpers/getRedirection'
import {
  PaymentError,
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterProps,
  PaymentSetupError,
  PaymentSubmitError,
  type SubmitStartData,
} from '../../../form-common/types'
import { PaymentMethodMisconfiguredError } from '../../../form-common/types/PaymentMethodMisconfiguredError'
import type { VirtualCardConcreteValue } from '../../../form-common/types/VirtualCardFormState'
import { useProcessOutForm } from '../../composables/useProcessOutForm'
import { getExceptionReadableMessage } from '../../helpers/getExceptionReadableMessage'
import { isProcessOutException } from '../../helpers/isProcessOutException'

import translations from './ProcessOutCardAdapter.translations'
import ProcessOutInput from './ProcessOutInput.vue'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const i18n = useI18n()
const logger = useLogger()

const publicKey = computed(() => {
  const config = props.paymentMethod?.config ?? {}

  return 'publicKey' in config ? config.publicKey : null
})

const preferredNetworks = computed(() => {
  const config = props.paymentMethod?.config ?? {}

  return ('preferredNetworks' in config ? config.preferredNetworks : []) ?? []
})

const {
  setupForm,
  virtualForm,
  tokenizeForm,
  makeCardPayment,
  availableNetworks,
} = useProcessOutForm()
const container = ref<HTMLElement | null>(null)
const selectedNetwork = ref<PaymentNetwork | null>(null)

onMounted(async () => {
  if (container.value === null) {
    throw new Error('PO container not found')
  }

  emit('setup-start')

  try {
    if (publicKey.value === null) {
      throw new PaymentMethodMisconfiguredError('PO public key not found')
    }

    await setupForm(publicKey.value, container.value)

    emit('setup-success')
  } catch (error) {
    const setupError = PaymentSetupError.fromAnyError(error)

    if (isProcessOutException(error)) {
      setupError.readableMessage = getExceptionReadableMessage(i18n, error)
      setupError.source = 'FRONT_LIBRARY_PROCESSOUT'
    }

    emit('setup-error', setupError)
  }
})

const handleSubmit = async (values: VirtualCardConcreteValue) => {
  emit('submit-start', {
    network: selectedNetwork.value,
    availableNetworks: availableNetworks.value,
    preferredNetworks: preferredNetworks.value,
  } as SubmitStartData)

  try {
    const token = await tokenizeForm({
      name: values.name,
      selectedNetwork: selectedNetwork.value,
    })

    const {
      paymentId,
      gatewayReference: invoiceId,
      gatewayTokenReference,
    } = await props.createPayment({
      gateway_token_reference: token,
    })

    if (!invoiceId) {
      throw new PaymentError(
        'No gatewayReference (invoiceId) found after creating the payment',
        {
          type: '/errors/payment/unexpected/api-response',
        },
      )
    }

    try {
      await makeCardPayment(invoiceId, gatewayTokenReference ?? token, {
        authorize_only: !props.paymentMethod?.autoCaptureEnabled,
      })
    } catch (error) {
      // When the finally throws an error, it overrides the first error
      // so we log the error before
      logger.error(
        '[PAYMENT] Error while making the payment with the PO library',
        { error: error as Error, owners: ['bot-squad-payin-front'] },
      )

      throw error
    } finally {
      // calls `paymentConfirmCard` endpoint regardless the payment it is a success or not.
      await $httpFetch(postConfirmCard, {
        pathParams: { paymentId },
      })
    }

    emit('submit-success', {
      paymentId,
      redirection: getRedirectionToPaymentResult(paymentId),
    })
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}
</script>
