<template>
  <BannerBase background="bg-[#FF98C1]/20" :is-busy>
    <RawKlarnaPlacement
      :base-price="props.basePrice"
      :payment-method="props.paymentMethod"
    />
  </BannerBase>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import type { Price } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { useKlarnaLibrary } from '../../composables/useKlarnaLibrary'
import BannerBase from '../BannerBase/BannerBase.vue'

import RawKlarnaPlacement from './RawKlarnaPlacement.vue'

const isBusy = ref(true)

const props = defineProps<{
  paymentMethod: PaymentMethod
  basePrice: Price
}>()

const { onLoaded } = useKlarnaLibrary(props.paymentMethod)
onLoaded(async (lib) => {
  // klarna script load in two phases.
  // The first one is the script tag that trigger this onLoaded callback.
  // Then the script will load the OnsiteMessaging script object.
  // There is no event to know when the OnsiteMessaging script is loaded.
  // But as klarna uses web components, we can wait for the klarna-placement custom element to be defined.
  const BUSY_TIMEOUT = 5000

  // safety net in case the Klarna API change
  const timeout = setTimeout(() => {
    isBusy.value = false
  }, BUSY_TIMEOUT)

  const toggleBusyOnPlacementRendered = () => {
    lib.OnsiteMessaging?.on('placement-rendered', () => {
      isBusy.value = false
      if (timeout) {
        clearTimeout(timeout)
      }
    })
  }

  if ('OnsiteMessaging' in lib) {
    toggleBusyOnPlacementRendered()
  } else {
    await window.customElements.whenDefined('klarna-placement')
    toggleBusyOnPlacementRendered()
  }
})
</script>
