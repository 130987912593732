import { useScriptTag } from '#imports'
import { onMounted, onUnmounted } from 'vue'

import { PaymentError } from '../../form-common/types'

export const PROCESSOUT_LIBRARY_URL = 'https://js.processout.com/processout.js'

/**
 * Load the ProcessOut library as soon as the component is mounted.
 * And, provide a function to wait and get the ProcessOut library once it's loaded.
 */
export function useProcessOutLibrary() {
  onUnmounted(() => {
    // Without this, the script is unmounted by useScriptTag
    // but the ProcessOut object is still available.
    // (causing issue if we try to use the object again)
    window.ProcessOut = undefined
  })

  const script = useScriptTag(PROCESSOUT_LIBRARY_URL, undefined, {
    immediate: false,
  })

  let loadingPromise: Promise<void> | null = null
  const loadIfNeeded = () => {
    if (window.ProcessOut) {
      // If the script is already loaded, do nothing
      return Promise.resolve()
    }

    if (!loadingPromise) {
      // Start loading the script if it's not already loading
      loadingPromise = (async () => {
        try {
          await script.load()
        } finally {
          loadingPromise = null
        }
      })()
    }

    return loadingPromise
  }

  onMounted(async () => {
    try {
      await loadIfNeeded()
    } catch {
      // do nothing here, let `getProcessOutLibrary` handle the error
    }
  })

  return {
    /**
     * Wait until the ProcessOut library is loaded and return it.
     * Throw a PaymentError if the library failed to load.
     */
    getProcessOutLibrary: async () => {
      try {
        await loadIfNeeded()
      } catch (error) {
        throw new PaymentError('ProcessOut library failed to load', {
          cause: error,
          source: 'FRONT_LIBRARY_PROCESSOUT',
          type: '/errors/payment/network-error',
        })
      }

      if (!window.ProcessOut) {
        // Something is wrong with the ProcessOut library
        // once loaded, it should be available on the window object.
        throw new PaymentError('ProcessOut library not loaded correctly', {
          source: 'FRONT_LIBRARY_PROCESSOUT',
          type: '/errors/payment/unexpected',
        })
      }

      return window.ProcessOut
    },
  }
}
