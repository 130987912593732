<template>
  <RevForm
    :id
    class="mt-12"
    :initial-values
    :name="id"
    :validate
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <RevInputSelect
        v-if="hasIssuers"
        id="issuer"
        v-model="values.issuer"
        class="mt-12"
        :error="errors.issuer"
        :label="i18n(translations.issuerLabel)"
        :options="issuersOptions"
      />
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevForm, makeValidate, required } from '@ds/components/Form'
import { RevInputSelect } from '@ds/components/InputSelect'

import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterProps,
  PaymentSubmitError,
  type SubmitSuccessData,
} from '../../../form-common/types'

import translations from './AdyenHostedAdapter.translations'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const i18n = useI18n()

const issuers = computed(() => {
  // TODO: [PAYIN-3639] Log when the payment method config is not well formatted
  const config = props.paymentMethod?.config ?? {}

  return 'issuers' in config ? config.issuers : []
})

const hasIssuers = computed(() => {
  return issuers.value.length > 0
})

const issuersOptions = computed(() => {
  return issuers.value.map((issuer) => ({
    value: issuer.id,
    label: issuer.name,
  }))
})

type Values =
  | {
      issuer: string
    }
  | Record<string, never>

const initialValues = computed(
  (): Values => (hasIssuers.value ? { issuer: '' } : {}),
)

const validate = makeValidate<Values>({
  issuer: required(i18n(translations.issuerRequired)),
})

const handleSubmit = async (values: Values) => {
  emit('submit-start')

  try {
    const result = await props.createPayment(
      hasIssuers.value ? { issuer_id: values.issuer } : {},
    )
    emit('submit-success', result as SubmitSuccessData)
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}
</script>
