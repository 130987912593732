import { useRuntimeConfig } from '#imports'

import {
  AFFIRM_SDK_URL_PRODUCTION,
  AFFIRM_SDK_URL_SANDBOX,
} from '../components/AffirmModal/AffirmModal.config'

import { useScript } from './useScript'

// TODO, merge this with the other `useAffirmLibrary`
export const useAffirmLibrary = () => {
  const { AFFIRM_API_KEY, AFFIRM_SANDBOX_ENABLED } = useRuntimeConfig().public

  if (process.client) {
    // eslint-disable-next-line no-underscore-dangle
    window._affirm_config = {
      public_api_key: AFFIRM_API_KEY,
    }
  }
  const url = AFFIRM_SANDBOX_ENABLED
    ? AFFIRM_SDK_URL_SANDBOX
    : AFFIRM_SDK_URL_PRODUCTION

  return useScript(url, {
    use() {
      return window.affirm
    },
  })
}
