import { PaymentNetwork } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

export const ProcessoutCardSchemes = {
  AMERICAN_EXPRESS: 'american-express',
  ARGENCARD: 'argencard',
  BANCONTACT: 'bancontact',
  CABAL: 'cabal',
  CARDGUARD: 'cardguard ead bg ils',
  CARTES_BANCAIRES: 'carte bancaire',
  DANKORT: 'dankort',
  DINERS_CLUB: 'diners-club',
  DISCOVER: 'discover',
  ELO: 'elo',
  HIPERCARD: 'hipercard',
  JCB: 'jcb',
  MAESTRO: 'maestro',
  MASTERCARD: 'mastercard',
  NARANJA: 'naranja',
  UATP: 'uatp',
  UNION_PAY: 'union-pay',
  VISA: 'visa',
} as const
export type ProcessoutCardSchemes = UnionOfPropertyTypes<
  typeof ProcessoutCardSchemes
>

export const PROCESSOUT_CARD_NETWORK_ID_BY_SCHEME: Record<
  ProcessoutCardSchemes,
  PaymentNetwork | null
> = {
  [ProcessoutCardSchemes.AMERICAN_EXPRESS]: PaymentNetwork.AMERICAN_EXPRESS,
  [ProcessoutCardSchemes.ARGENCARD]: null,
  [ProcessoutCardSchemes.BANCONTACT]: PaymentNetwork.BANCONTACT,
  [ProcessoutCardSchemes.CABAL]: null,
  [ProcessoutCardSchemes.CARDGUARD]: null,
  [ProcessoutCardSchemes.CARTES_BANCAIRES]: PaymentNetwork.CARTES_BANCAIRES,
  [ProcessoutCardSchemes.DANKORT]: null,
  [ProcessoutCardSchemes.DINERS_CLUB]: null,
  [ProcessoutCardSchemes.DISCOVER]: PaymentNetwork.DISCOVER,
  [ProcessoutCardSchemes.ELO]: null,
  [ProcessoutCardSchemes.HIPERCARD]: null,
  [ProcessoutCardSchemes.JCB]: null,
  [ProcessoutCardSchemes.MAESTRO]: PaymentNetwork.MAESTRO,
  [ProcessoutCardSchemes.MASTERCARD]: PaymentNetwork.MASTERCARD,
  [ProcessoutCardSchemes.NARANJA]: null,
  [ProcessoutCardSchemes.UATP]: null,
  [ProcessoutCardSchemes.UNION_PAY]: null,
  [ProcessoutCardSchemes.VISA]: PaymentNetwork.VISA,
} as const
