import type { PaymentNetwork } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

/**
 * Sort the payment networks by putting the preferred ones first.
 * @param networksIds The payment networks to sort.
 * @param preferredNetworksIds The preferred payment networks sorted by priority.
 */
export function sortPaymentNetworkByPreference(
  networksIds: PaymentNetwork[],
  preferredNetworksIds: PaymentNetwork[] | null = null,
) {
  if (!preferredNetworksIds) {
    return networksIds
  }

  return [...(networksIds || [])].sort((brandA) =>
    preferredNetworksIds.includes(brandA) ? -1 : 1,
  )
}
