import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

export const AdyenVirtualCardFieldIds = {
  NUMBER: 'encryptedCardNumber',
  DATE: 'encryptedExpiryDate',
  SECURITY_CODE: 'encryptedSecurityCode',
} as const

export type AdyenVirtualCardFieldIds = UnionOfPropertyTypes<
  typeof AdyenVirtualCardFieldIds
>

/**
 * When providing error and data, Adyen split the date in two fields: month and year.
 * Thus, this type is used to represent the adyen data.
 */
export type AdyenVirtualCardDataIds =
  | 'encryptedCardNumber'
  | 'encryptedExpiryMonth'
  | 'encryptedExpiryYear'
  | 'encryptedSecurityCode'
