export default {
  BUYER_ACCOUNT_ERROR_title: {
    id: 'adyen_google_pay_buyer_account_error_title',
    defaultMessage: 'Payment with GooglePay failed',
  },
  BUYER_ACCOUNT_ERROR_description: {
    id: 'adyen_google_pay_buyer_account_error_description',
    defaultMessage:
      "We're sorry but your payment with GooglePay has failed. Please try again, or try another payment method.",
  },
  DEVELOPER_ERROR_title: {
    id: 'adyen_google_pay_developer_error_title',
    defaultMessage: 'Payment with GooglePay failed',
  },
  DEVELOPER_ERROR_description: {
    id: 'adyen_google_pay_developer_error_description',
    defaultMessage:
      "We're sorry but your payment with GooglePay has failed. Please try again, or try another payment method.",
  },
  MERCHANT_ACCOUNT_ERROR_title: {
    id: 'adyen_google_pay_merchant_account_error_title',
    defaultMessage: 'Payment with GooglePay failed',
  },
  MERCHANT_ACCOUNT_ERROR_description: {
    id: 'adyen_google_pay_merchant_account_error_description',
    defaultMessage:
      "We're sorry but your payment with GooglePay has failed. Please try again, or try another payment method.",
  },
  INTERNAL_ERROR_title: {
    id: 'adyen_google_pay_google_internal_error_title',
    defaultMessage: 'Payment with GooglePay failed',
  },
  INTERNAL_ERROR_description: {
    id: 'adyen_google_pay_google_internal_error_description',
    defaultMessage:
      "We're sorry but your payment with GooglePay has failed. Please try again, or try another payment method.",
  },
  defaultErrorTitle: {
    id: 'adyen_google_pay_default_error_title',
    defaultMessage: 'Payment with GooglePay failed',
  },
  defaultErrorDescription: {
    id: 'adyen_google_pay_default_error_description',
    defaultMessage:
      "We're sorry but your payment with GooglePay has failed. Please try again, or try another payment method.",
  },
}
