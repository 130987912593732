import { type MaybeRef, computed, toRef } from 'vue'

import type {
  PaymentGroup,
  PaymentMethod,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { sortByProperty } from '@backmarket/utils/collection/sortByProperty'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import {
  type PaymentFormVersion,
  usePaymentFormVersion,
} from '../../form-common/composables/usePaymentFormVersion'
import { PAYMENT_GROUP_CONFIGS } from '../config/methods'
import type { PaymentGroupData } from '../config/methods.type'
import { isPaymentMethodEnabled, paymentGroupConfig } from '../helpers/methods'

export function getSortedPaymentGroups(
  paymentMethod: PaymentMethod[],
  formVersion: PaymentFormVersion,
): PaymentGroupData[] {
  const paymentMethodByGroup = paymentMethod.reduce<
    Partial<Record<PaymentGroup, PaymentMethod[]>>
  >(
    (acc, method) => ({
      ...acc,
      [method.group]: [...(acc[method.group] || []), method],
    }),
    {},
  )

  const groups = Object.entries(paymentMethodByGroup).map((entries) => {
    const groupId = entries[0] as PaymentGroup
    const paymentMethods = entries[1]

    const config = paymentGroupConfig(groupId, {
      methods: paymentMethods,
      formVersion,
    })
    const enabledPaymentMethods = paymentMethods.filter(isPaymentMethodEnabled)

    return {
      id: groupId,
      enabledMethods: enabledPaymentMethods,
      hasManyEnabledMethods: enabledPaymentMethods.length > 1,
      hasMessages: !isEmpty(config.messages),
      isEnabled: !isEmpty(enabledPaymentMethods),
      label: config.label,
      methods: paymentMethods,
      config,
    }
  })

  return sortByProperty(
    groups,
    (group) => group.id,
    Object.keys(PAYMENT_GROUP_CONFIGS) as PaymentGroup[],
  )
}

/**
 * Some payment methods can be "grouped" together. When a group contains
 * multiple payment methods, a "method picker" will be displayed to switch
 * between them. For this component, for the top level list (radio buttons),
 * we iterate over groups. We use payment method's `group` property for this
 * purpose.
 */
export const usePaymentMethodGroups = (
  paymentMethods: MaybeRef<PaymentMethod[]>,
) => {
  const formVersion = usePaymentFormVersion()

  return computed(() =>
    getSortedPaymentGroups(toRef(paymentMethods).value, formVersion),
  )
}
