import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'

import { type PostPaymentAuthorize } from './PostPaymentAuthorize.specs'

export function usePaymentAuthorize() {
  const $httpFetch = useHttpFetch()

  return ({ pathParams, body }: Omit<PostPaymentAuthorize, 'response'>) =>
    $httpFetch('/payment/:paymentId/authorize', {
      method: 'POST',
      pathParams,
      body,
    })
}
