import { useAsyncData, useRuntimeConfig } from '#imports'
import { type MaybeRef, toValue } from 'vue'

import type {
  PaymentCreateFraudData,
  PaymentMethod,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { splitHostname } from '@backmarket/utils/url/splitHostname'
import { watchImmediate } from '@vueuse/core'

import { useSignifyd } from '../../../fraud/composables/useSignifyd'
import type { LocalPaymentMethod } from '../../../methods/config/methods.type'

// TODO [PAYIN-3532] We should have an API similar to useScriptTag to load Ravelin
// Especially to get retries and logging
const useRavelinLibrary = () => {
  const load = async () => {
    const { default: Ravelin } = await import('ravelinjs/core+track')

    return Ravelin
  }

  return { load }
}

/**
 * Returns a unique device ID for Ravelin. Loads the library if needed.
 */
const useRavelinId = () => {
  const {
    public: { RAVELIN_KEY },
  } = useRuntimeConfig()

  const { load } = useRavelinLibrary()

  // Load the library and initialize the instance
  const getRavelinInstance = async (ravelinKey: string) => {
    const Ravelin = await load()

    const { domain, extension } = splitHostname(window.location.host)

    return new Ravelin({
      key: ravelinKey,

      // As recommended, setting the cookie domain at as high a level as possible,
      // e.g. mysite.com rather than subdomain.mysite.com
      // Read more: https://developer.ravelin.com/libraries-and-sdks/ravelinjs/v1/#reference
      cookieDomain: `${domain}.${extension}`,
    })
  }

  return async () => {
    try {
      const ravelinInstance = await getRavelinInstance(RAVELIN_KEY)

      return ravelinInstance.core.id()
    } catch (error) {
      // Silent catch, the payment should not be blocked by this
    }

    return null
  }
}

/**
 * Returns the fraud data needed to create a payment.
 *
 * To assess the probability of fraud, we rely on third party providers.
 * Depending on the payment method, this composable will
 * - load the needed third-party library
 * - get and format the fraud data needed for the payment creation
 */
export const useFraudData = (
  paymentMethod: MaybeRef<
    PaymentMethod | LocalPaymentMethod | undefined | null
  >,
) => {
  const { load: getSignifydSessionId } = useSignifyd()
  const getRavelinDeviceId = useRavelinId()

  const asyncDataResult = useAsyncData(
    async (): Promise<PaymentCreateFraudData> => {
      const method = toValue(paymentMethod)
      if (method && 'isLocal' in method) {
        return {}
      }
      let signifydSessionId = null
      let ravelinDeviceId = null

      switch (method?.fraudCheckPartner) {
        case 'signifyd':
          signifydSessionId = await getSignifydSessionId()
          break
        case 'ravelin':
          ravelinDeviceId = await getRavelinDeviceId()
          break
        default:
          break
      }

      return {
        ...(signifydSessionId && { signifyd_fingerprint: signifydSessionId }),
        ...(ravelinDeviceId && { deviceId: ravelinDeviceId }),
      }
    },
    {
      server: false,
    },
  )

  watchImmediate(
    () => {
      const method = toValue(paymentMethod)
      if (!method || 'isLocal' in method) {
        return ''
      }

      return method?.fraudCheckPartner
    },
    () => {
      // TODO [PAYIN-3855] We should add tests for this
      void asyncDataResult.execute()
    },
  )

  return asyncDataResult
}
