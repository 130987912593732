import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { AdyenChangePayload } from '../types/AdyenEventPayload'

/**
 * Tells if the payload corresponds to an touched Adyen form. (Just after form initialization and before the user type inside a fields)
 */
export function isAdyenFormTouched(
  payload: AdyenChangePayload | null,
): payload is AdyenChangePayload {
  return Boolean(payload && (payload.isValid || !isEmpty(payload.valid)))
}
