import { PaymentNetwork } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import type { VirtualCardFieldValues } from '../../types/VirtualCardFormState'

export const ADYEN_SANDBOX_CARDS_URL =
  'https://docs.adyen.com/development-resources/testing/test-card-numbers/'

// Avoid using a name such as "Visa (3DS)", because that would trigger
// ProcessOut errors such as `gateway.possible-fraud`, since Adyen
// RevenueProtect is enabled on ProcessOut -> Adyen gateway.
// Read more: https://www.adyen.com/risk-management
const NAME = 'John Cena'

export type SandboxCardItem = {
  label: string
  network?: PaymentNetwork
  showInTestingAssistant?: boolean
  values: VirtualCardFieldValues
}

export const ADYEN_SANDBOX_CARDS: SandboxCardItem[] = [
  {
    label: 'AmEx',
    network: PaymentNetwork.AMERICAN_EXPRESS,
    showInTestingAssistant: true,
    values: {
      name: NAME,
      number: '3700 000000 00002',
      expiryDate: '03/30',
      securityCode: '7373',
    },
  },
  {
    label: 'Bancontact',
    network: PaymentNetwork.BANCONTACT,
    showInTestingAssistant: true,
    values: {
      name: NAME,
      number: '6703 0000 0000 0000 003',
      expiryDate: '03/30',
      securityCode: '',
    },
  },
  {
    label: 'Cartes Bancaires',
    network: PaymentNetwork.CARTES_BANCAIRES,
    showInTestingAssistant: false,
    values: {
      name: NAME,
      number: '4360 0000 0100 0005',
      expiryDate: '03/30',
      securityCode: '737',
    },
  },
  {
    label: 'Discover',
    network: PaymentNetwork.DISCOVER,
    showInTestingAssistant: false,
    values: {
      name: NAME,
      number: '6011 6011 6011 6611',
      expiryDate: '03/30',
      securityCode: '737',
    },
  },
  {
    label: 'Mastercard',
    network: PaymentNetwork.MASTERCARD,
    showInTestingAssistant: false,
    values: {
      name: NAME,
      number: '2222 4000 7000 0005',
      expiryDate: '03/30',
      securityCode: '737',
    },
  },
  {
    label: 'Visa',
    network: PaymentNetwork.VISA,
    showInTestingAssistant: true,
    values: {
      name: NAME,
      number: '4977 9494 9494 9497',
      expiryDate: '03/30',
      securityCode: '737',
    },
  },
  {
    label: 'Visa (3DS)',
    network: PaymentNetwork.VISA,
    showInTestingAssistant: true,
    values: {
      name: NAME,
      number: '4917 6100 0000 0000',
      expiryDate: '03/30',
      securityCode: '737',
    },
  },
  {
    label: 'Visa (no CSC)',
    network: PaymentNetwork.VISA,
    showInTestingAssistant: true,
    values: {
      name: NAME,
      number: '5555 3412 4444 1115',
      expiryDate: '03/30',
      securityCode: '',
    },
  },
] as const
