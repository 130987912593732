import type { ProcessOutException } from '../../../types/processout'

/** Determine if an error was emitted by the ProcessOut library */
export const isProcessOutException = (
  error: unknown,
): error is ProcessOutException =>
  Boolean(
    error &&
      typeof error === 'object' &&
      'name' in error &&
      error.name === 'ProcessOutException',
  )
