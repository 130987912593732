import {
  AdyenCardBrands,
  PaymentNetwork,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

// https://docs.adyen.com/payment-methods/cards/custom-card-integration#supported-card-types
export const ADYEN_CARD_NETWORK_ID_BY_BRAND = {
  [AdyenCardBrands.AMEX]: PaymentNetwork.AMERICAN_EXPRESS,
  [AdyenCardBrands.BCMC]: PaymentNetwork.BANCONTACT,
  [AdyenCardBrands.CARTEBANCAIRE]: PaymentNetwork.CARTES_BANCAIRES,
  [AdyenCardBrands.DISCOVER]: PaymentNetwork.DISCOVER,
  [AdyenCardBrands.JCB]: PaymentNetwork.JCB,
  [AdyenCardBrands.MAESTRO]: PaymentNetwork.MAESTRO,
  [AdyenCardBrands.MAESTROUK]: PaymentNetwork.MAESTRO,
  [AdyenCardBrands.MC]: PaymentNetwork.MASTERCARD,
  [AdyenCardBrands.VISA]: PaymentNetwork.VISA,
  [AdyenCardBrands.VISAALPHABANKBONUS]: PaymentNetwork.VISA,
  [AdyenCardBrands.VISADANKORT]: PaymentNetwork.VISA,
}
