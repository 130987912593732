import type { I18n } from '@backmarket/nuxt-module-i18n/types'
import {
  FORM_VALID,
  type Validator,
  matchingRegExp,
  required,
} from '@ds/components/Form'

import translations from './InputCreditCardName.translations'

// Naive regular expression for validating credit card name
// Inspired from Adyen library:
//
//    validations.holderNameCheck = function ( val ) {
//      return (val && val.match && val.match(/\S/)) ? true : false;
//    };
const REGEXP_ANY_NON_SPACE = /\S/

const CARD_NAME_MAX_DIGITS = 5

const validateMaxDigits =
  <Values extends object>(i18n: I18n): Validator<Values> =>
  (value) => {
    const digits = String(value).replace(/\D/g, '')

    return digits.length > CARD_NAME_MAX_DIGITS
      ? i18n(translations.invalid)
      : FORM_VALID
  }

export const InputCreditCardNameValidators = (i18n: I18n) => ({
  REQUIRED: required(i18n(translations.required)),
  REGEXP_ANY_NON_SPACE: matchingRegExp(
    REGEXP_ANY_NON_SPACE,
    i18n(translations.invalid),
  ),
  REGEXP_CHECK_DIGIT: validateMaxDigits(i18n),
})
