import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import type { LocalPaymentMethod } from '../../../methods/config/methods.type'

export const getPaymentSubmitButtonDataQAAttributes = (
  method: PaymentMethod | LocalPaymentMethod | null,
) => {
  const methodAttributes =
    method && 'isLocal' in method
      ? {}
      : {
          'data-qa-architecture': method?.architecture,
          'data-qa-bm-code': method?.bmCode,
          'data-qa-integration-type': method?.integrationType,
          'data-qa-psp-code': method?.pspCode,
        }

  return {
    ...methodAttributes,
    'data-qa': 'submit-button',
  }
}
