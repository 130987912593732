<template>
  <component
    :is="adapter"
    :id
    ref="instance"
    :base-price
    :create-payment
    :disabled="isDisabled"
    :payment-method
    @setup-error="onSetupError"
    @setup-start="onSetupStart"
    @setup-success="onSetupSuccess"
    @submit-error="onSubmitError"
    @submit-start="onSubmitStart"
    @submit-success="onSubmitSuccess"
  />
</template>

<script lang="ts" setup>
import { computed, ref, shallowRef, toRef, watch } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { refAutoReset } from '@vueuse/core'

import type {
  PaymentFormAdapterFullProps as AdapterProps,
  PaymentFormAdapter,
  PaymentFormAdapterEmits,
  PaymentFormAdapterInstance,
  PaymentFormAdapterProps,
  SubmitSuccessData,
} from '../../../../form-common/types'

const props = defineProps<
  {
    adapter: PaymentFormAdapter
  } & PaymentFormAdapterProps
>()

const emit = defineEmits<PaymentFormAdapterEmits>()
const instance = shallowRef<PaymentFormAdapterInstance | null>(null)

// Redirecting is an asynchronous operation done outside the payment form.
// We need to keep the form in a loading state during this time
// We currently have no way of knowing when the redirection is done.
// Note that it doesn't matter much as when redirecting, the payment form will be unmounted.
// So, in theory, we could set and keep `isRedirecting` to true and forget about it
// But if for some reason a navigation fails, we reset the ref to false after some time to let the user try again.
const isRedirecting = refAutoReset(false, 10_0000)
const logger = useLogger()
watch(isRedirecting, (value) => {
  if (!value) {
    logger.error(
      'The user is still on the payment page after an adapter asks for a redirection',
      {
        owners: ['bot-squad-payin-front'],
      },
    )
  }
})

const isSettingUp = ref(false)
const isSubmitting = ref(false)
const isBusy = computed(
  () => isSettingUp.value || isSubmitting.value || isRedirecting.value,
)
const isDisabled = computed(() => props.disabled || isBusy.value)

watch(toRef(props, 'adapter'), () => {
  // TODO: [PAYIN-3847] This state could be managed by the adapter,
  // and adapters should be kept alive between payment method changes
  isSettingUp.value = false
  isSubmitting.value = false
  isRedirecting.value = false
})

const onSetupStart: AdapterProps['onSetupStart'] = (...args) => {
  isSettingUp.value = true
  emit('setup-start', ...args)
}

const onSetupSuccess: AdapterProps['onSetupSuccess'] = (...args) => {
  isSettingUp.value = false
  emit('setup-success', ...args)
}

const onSetupError: AdapterProps['onSetupError'] = (...args) => {
  isSettingUp.value = false
  emit('setup-error', ...args)
}

const onSubmitStart: AdapterProps['onSubmitStart'] = (...args) => {
  isSubmitting.value = true
  emit('submit-start', ...args)
}

const onSubmitSuccess: AdapterProps['onSubmitSuccess'] = (
  data: SubmitSuccessData,
) => {
  if (data.redirection) {
    isRedirecting.value = true
  }
  isSubmitting.value = false
  emit('submit-success', data)
}

const onSubmitError: AdapterProps['onSubmitError'] = (...args) => {
  isSubmitting.value = false
  emit('submit-error', ...args)
}

defineExpose({
  instance,
  isBusy,
  isDisabled,
})
</script>
