import { useLazyAsyncData } from '#imports'
import { type MaybeRefOrGetter, toValue } from 'vue'

import {
  type PaymentMethod,
  PaymentMethodCode,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { PAYMENT_METHOD_CONFIGS } from '../config/methods'

export function usePaymentMethodAvailabilityContext(
  paymentMethod: PaymentMethod,
) {
  const config = PAYMENT_METHOD_CONFIGS[paymentMethod.bmCode]

  return !!config && 'useAvailabilityContext' in config
    ? config.useAvailabilityContext(paymentMethod || {})
    : undefined
}

async function isPaymentMethodAvailable(
  paymentMethod: PaymentMethod,
  context: unknown,
) {
  const isAvailableInBrowser = (PAYMENT_METHOD_CONFIGS[paymentMethod.bmCode]
    ?.isAvailableInBrowser || (() => true)) as (
    config: PaymentMethod['config'],
    context: unknown,
  ) => Promise<boolean>

  return (
    process.server || isAvailableInBrowser(paymentMethod.config || {}, context)
  )
}

/**
 * Filter a list of payment methods, and retain only those that are available
 * in the user's browser. It relies on `isAvailableInBrowser` from
 * `PAYMENT_METHOD_CONFIGS`, or consider it is always available if not defined.
 * Note that it will return the same list on server-side.
 */
export async function filterAvailablePaymentMethods(
  paymentMethods: PaymentMethod[],
  contexts: Record<PaymentMethodCode, unknown>,
) {
  const availability = await Promise.all(
    paymentMethods.map((paymentMethod) =>
      isPaymentMethodAvailable(paymentMethod, contexts[paymentMethod.bmCode]),
    ),
  )

  return paymentMethods.filter((_, i) => availability[i])
}

/**
 * Get the payment methods available in the current browser context.
 * This will only resolve in the browser and keep pending on the server.
 */
export const useAvailablePaymentMethods = (
  paymentMethods: MaybeRefOrGetter<PaymentMethod[]>,
) => {
  const contexts = toValue(paymentMethods).reduce(
    (ctx, paymentMethod) => ({
      ...ctx,
      [paymentMethod.bmCode]:
        usePaymentMethodAvailabilityContext(paymentMethod),
    }),
    {} as Record<PaymentMethodCode, unknown>,
  )

  const asyncData = useLazyAsyncData(
    () => filterAvailablePaymentMethods(toValue(paymentMethods), contexts),
    {
      server: false,
      default: () => toValue(paymentMethods),
    },
  )

  return { ...asyncData, availablePaymentMethods: asyncData.data }
}
