import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

/**
 * Errors returned by ProcessOut SDK.
 *
 * This list represents the known ones, and is intended to be completed/updated
 * in the future, based on experience and log collection.
 *
 * Non documented errors are annotated with `[UNDOCUMENTED]`.
 *
 * > Note that this page does not reflect all possible error codes returned by
 * > the ProcessOut API or SDKs: new codes are added on a daily basis and depend
 * > on PSPs, banking partners and issuing banks. It is highly recommended to
 * > handle cases where the returned error code isn’t known by your application
 * > as payment declines.
 *
 * Read more: https://docs.processout.com/payments/error-codes/
 */
export const ProcessOutExceptionCodes = {
  // The payment was declined, but no further information was returned
  PAYMENT_DECLINED: 'payment.declined',

  // The gateway that attempted to process the payment returned a generic
  // decline. This can be caused by validation errors, fraud prevention tool or
  // other specific errors.
  GATEWAY_DECLINED: 'gateway.declined',

  // [UNDOCUMENTED] The gateway declined payment because of possible fraud.
  GATEWAY_POSSIBLE_FRAUD: 'gateway.possible-fraud',

  // The card requires a 3DS authentication to be performed, for example in the
  // scope of 3DS2/SCA
  CARD_NEEDS_AUTHENTICATION: 'card.needs-authentication',

  // Similarly to payment.declined, the card payment was declined with no
  // further information
  CARD_DECLINED: 'card.declined',

  // Do Not Honor is the default error code sent by bank, without any additional
  // information
  CARD_DO_NOT_HONOR: 'card.do-not-honor',

  // No action was done by the payment provider, and should be retried
  CARD_NO_ACTION_TAKEN: 'card.no-action-taken',

  // The payment should be retried
  CARD_PLEASE_RETRY: 'card.please-retry',

  // The transaction represented a security threat during its processing and was
  // declined
  CARD_SECURITY_VIOLATION: 'card.security-violation',

  // The acquirer used by the payment processor failed to process the
  // transaction
  CARD_ACQUIRER_FAILED: 'card.acquirer-failed',

  // The card holder bank failed to process the transaction
  CARD_ISSUER_FAILED: 'card.issuer-failed',

  // The processing failed at the acquirer or card holder bank level
  CARD_PROCESSING_ERROR: 'card.processing-error',

  // The card holder bank could not process the payment
  CARD_ISSUER_DOWN: 'card.issuer-down',

  // The card maximum payment attempts were reached- the customer should contact
  // its bank
  CARD_MAXIMUM_ATTEMPTS: 'card.maximum-attempts',

  // The card holder bank declined the payment, and should be contacted by your
  // customer
  CARD_CONTACT_BANK: 'card.contact-bank',

  // The card limits were reached (ex: amounts, transactions volume) and the
  // customer should contact its bank
  CARD_EXCEEDED_LIMITS: 'card.exceeded-limits',

  // The card withdrawal limit was reached, the customer should contact its bank
  CARD_EXCEEDED_WITHDRAWAL_LIMIT: 'card.exceeded-withdrawal-limit',

  // The card activity limit was reached, the customer should contact its bank
  CARD_EXCEEDED_ACTIVITY_LIMITS: 'card.exceeded-activity-limits',

  // The card has no money left in its bank account, the customer should add
  // more funds
  CARD_NO_MONEY: 'card.no-money',

  // The payment was blocked for potential fraud
  CARD_POSSIBLE_FRAUD: 'card.possible-fraud',

  // The transaction had high chances of being a duplicate, and was declined
  CARD_DUPLICATE: 'card.duplicate',

  // The payment provider could not find the card issuer bank
  CARD_ISSUER_NOT_FOUND: 'card.issuer-not-found',

  // The payment provider failed to contact the card network to process the
  // transaction
  CARD_NETWORK_FAILED: 'card.network-failed',

  // The card is not supported by the payment provider
  CARD_NOT_SUPPORTED: 'card.not-supported',

  // The currency is not supported by this card
  CARD_CURRENCY_UNSUPPORTED: 'card.currency-unsupported',

  // The card type was not supported by the payment provider
  CARD_TYPE_NOT_SUPPORTED: 'card.type-not-supported',

  // The card was not activated yet by the card holder or its bank
  CARD_NOT_ACTIVATED: 'card.not-activated',

  // The card was expired
  CARD_EXPIRED: 'card.expired',

  // The card was invalid (invalid number/expiration date/CVC)
  CARD_INVALID: 'card.invalid',

  // The card has an invalid number
  CARD_INVALID_NUMBER: 'card.invalid-number',

  // The card PIN was invalid. This error code does not apply for online
  // payments
  CARD_INVALID_PIN: 'card.invalid-pin',

  // The name on the card was invalid (potential AVS failure)
  CARD_INVALID_NAME: 'card.invalid-name',

  // The card expiration date was invalid
  CARD_INVALID_EXPIRY_DATE: 'card.invalid-expiry-date',
  // [UNDOCUMENTED] Year is the current one but date is in the past.
  // TODO Talk to ProcessOut to explain duplicates
  CARD_INVALID_DATE: 'card.invalid-date',

  // The card expiration month was invalid
  CARD_INVALID_EXPIRY_MONTH: 'card.invalid-expiry-month',
  // [UNDOCUMENTED] Date field has a month higher than 12.
  // TODO Talk to ProcessOut to explain duplicates.
  CARD_INVALID_MONTH: 'card.invalid-month',

  // The card expiration year was invalid
  CARD_INVALID_EXPIRY_YEAR: 'card.invalid-expiry-year',
  // [UNDOCUMENTED] Date field has a year in the past.
  // TODO Talk to ProcessOut to explain duplicates.
  CARD_INVALID_YEAR: 'card.invalid-year',

  // The card holder ZIP code was invalid (potential AVS failure)
  CARD_INVALID_ZIP: 'card.invalid-zip',

  // The card holder address was invalid (potential AVS failure)
  CARD_INVALID_ADDRESS: 'card.invalid-address',

  // [UNDOCUMENTED] The card CVC is invalid.
  CARD_INVALID_CVC: 'card.invalid-cvc',

  // The card CVC was missing, but needed to process the payment
  CARD_MISSING_CVC: 'card.missing-cvc',

  // The card expiry date was missing, but needed to process the payment
  CARD_MISSING_EXPIRY: 'card.missing-expiry',

  // The card number was missing
  CARD_MISSING_NUMBER: 'card.missing-number',

  // The card 3DS verification process was missing but needed to process the
  // payment
  CARD_MISSING_3_DS: 'card.missing-3ds',

  // The card CVC check failed
  CARD_FAILED_CVC: 'card.failed-cvc',

  // The card AVS check failed
  CARD_FAILED_AVS: 'card.failed-avs',

  // The card AVS check failed on the postal code
  CARD_FAILED_AVS_POSTAL: 'card.failed-avs-postal',

  // The card does not support 3DS authentication (but a 3DS authentication was
  // requested)
  CARD_UNSUPPORTED_3_DS: 'card.unsupported-3ds',

  // The card 3DS check failed
  CARD_FAILED_3_DS: 'card.failed-3ds',

  // The card 3DS check expired and needs to be retried
  CARD_EXPIRED_3_DS: 'card.expired-3ds',

  // The card AVS check failed on the address
  CARD_FAILED_AVS_ADDRESS: 'card.failed-avs-address',

  // Both the card CVC and AVS checks failed
  CARD_FAILED_CVC_AND_AVS: 'card.failed-cvc-and-avs',

  // [UNDOCUMENTED] Discovered during E2E tests. Example of root error message:
  // > Adyen returned AuthenticationNotRequired despite executeThreeD parameter.
  CARD_FAILED_3DS_TECHNICAL: 'card.failed-3ds-technical',

  // The track data of the card was invalid (expiration date or CVC)
  CARD_BAD_TRACK_DATA: 'card.bad-track-data',

  // The card is not authorized to make the payment
  CARD_NOT_AUTHORIZED: 'card.not-authorized',

  // The card was not yet registered and can therefore not process payments
  CARD_NOT_REGISTERED: 'card.not-registered',

  // The card was stolen
  CARD_STOLEN: 'card.stolen',

  // The card was lost by its card holder
  CARD_LOST: 'card.lost',

  // The payment should not be retried
  CARD_DONT_RETRY: 'card.dont-retry',

  // The card bank account was invalid, the customer should contact its bank
  CARD_INVALID_ACCOUNT: 'card.invalid-account',

  // The card was revoked
  CARD_REVOKED: 'card.revoked',

  // All the card holder cards were revoked
  CARD_REVOKED_ALL: 'card.revoked-all',

  // The card was a test card and can’t be used to process live transactions
  CARD_TEST: 'card.test',

  // The card was blacklisted from the payment provider
  CARD_BLACKLISTED: 'card.blacklisted',

  // [UNDOCUMENTED] Happens on client.setupForm when either the card number or
  // expiry date <div> placeholder is missing (note: security code missing does
  // not trigger it).
  PROCESSOUT_JS_UNDEFINED_FIELD: 'processout-js.undefined-field',

  // [UNDOCUMENTED] Happens on client.setupForm when a <div> placeholder is
  // misconfigured (missing ont of the data-processout attributes).
  PROCESSOUT_JS_INVALID_FIELD_TYPE: 'processout-js.invalid-field-type',

  // [UNDOCUMENTED] Happens on client.setupForm when a placeholder is an <input>
  // element already.
  PROCESSOUT_JS_INVALID_FIELD: 'processout-js.invalid-field',

  // [UNDOCUMENTED] Happens on client.tokenize when a field's <iframe> is
  // missing (removed prematurely, etc)
  PROCESSOUT_JS_FIELD_UNAVAILABLE: 'processout-js.field.unavailable',

  // [UNDOCUMENTED] Found in the SDK code:
  // > Your project ID was not specified when loading ProcessOut.js.
  PROCESSOUT_JS_MISSING_PROJECT_ID: 'processout-js.missing-project-id',

  // [UNDOCUMENTED] Found in the SDK code:
  // > ProcessOut.js was not loaded from ProcessOut CDN. Please do not host
  // > ProcessOut.js yourself but rather use ProcessOut CDN: https://js.processout.com/processout.js
  PROCESSOUT_JS_NOT_HOSTED: 'processout-js.not-hosted',

  // [UNDOCUMENTED] Found in the SDK code:
  // > The ProcessOut.js modal is unavailable.
  PROCESSOUT_JS_MODAL_UNAVAILABLE: 'processout-js.modal.unavailable',

  // [UNDOCUMENTED] Found in the SDK code:
  // > The provided gateway configuration is invalid.
  PROCESSOUT_JS_INVALID_CONFIG: 'processout-js.invalid-config',

  // [UNDOCUMENTED] Found in the SDK code:
  // > No customer action is required for the given gateway configuration and resource.
  PROCESSOUT_JS_NO_CUSTOMER_ACTION: 'processout-js.no-customer-action',

  // [UNDOCUMENTED] Found in the SDK code:
  // > The requested customer action is not supported by ProcessOut.js.
  PROCESSOUT_JS_CUSTOMER_ACTION_NOT_SUPPORTED:
    'processout-js.customer-action-not-supported',

  // [UNDOCUMENTED] Found in the SDK code:
  // > There seems to be some connectivity issue preventing the payment from
  // > making it through. Please switch to another network or try again in a few
  // > minutes.
  PROCESSOUT_JS_NETWORK_ISSUE: 'processout-js.network-issue',

  // [UNDOCUMENTED] Found in the SDK code:
  // > The specified parameter had an unknown type.
  PROCESSOUT_JS_INVALID_TYPE: 'processout-js.invalid-type',

  // [UNDOCUMENTED] Found in the SDK code:
  // > A source must be specified.
  PROCESSOUT_JS_MISSING_SOURCE: 'processout-js.missing-source',

  // [UNDOCUMENTED] Found in the SDK code:
  // > The requested action could not be performed on the given field because
  // > its type is invalid.
  // And
  // > RefreshCVC was called but the form has no CVC field initialized.
  PROCESSOUT_JS_WRONG_TYPE_FOR_ACTION: 'processout-js.wrong-type-for-action',

  // [UNDOCUMENTED] Found in the SDK code:
  // > An invoice ID must be specified.
  PROCESSOUT_JS_MISSING_INVOICE_ID: 'processout-js.missing-invoice-id',

  // [UNDOCUMENTED] Found in the SDK code:
  // > A resource ID must be specified.
  PROCESSOUT_JS_MISSING_RESOURCE_ID: 'processout-js.missing-resource-id',

  // [UNDOCUMENTED] Found in the SDK code:
  // > The customer canceled the payment.
  CUSTOMER_CANCELED: 'customer.canceled',

  // [UNDOCUMENTED] Found in the SDK code:
  // > Please allow pop-ups to continue with your payment flow.
  CUSTOMER_POPUP_BLOCKED: 'customer.popup-blocked',
} as const
export type ProcessOutExceptionCodes = UnionOfPropertyTypes<
  typeof ProcessOutExceptionCodes
>
