import type { PaymentCreateBody } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import type { PaymentFormCreateFunctionOptions } from '../types/PaymentFormCreateFunction'

/**
 * Translate the `PaymentFormCreateFunctionOptions` received from the `PaymentForm`
 * component into a `PaymentCreateBody` that can be used to create a payment.
 */
export const toCheckoutPaymentCreateBody = (
  options: PaymentFormCreateFunctionOptions,
): PaymentCreateBody => {
  return {
    payment_method: {
      config: options.config,
      reference: options.method?.reference,
    },
    requested_price: options.price,
    ...options.fraudData,
  }
}

// TODO: [PAYIN-3883]
export const toBuybackPaymentCreateBody = (
  options: PaymentFormCreateFunctionOptions,
) => {
  const reference =
    options.method?.reference === 'LEGACY_CARD'
      ? 'CB'
      : options.method?.reference

  return {
    method_payment: reference,
  }
}
