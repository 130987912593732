<template>
  <RevInputVirtual
    class="w-full"
    :disabled
    :error="props.error"
    :filled="props.filled"
    :focused="props.focused"
    :has-clear-button="false"
    helper-text-id="process-out-card-adapter"
    :invalid="props.invalid"
    :label
    :touched="props.touched"
  >
    <template #default>
      <div class="flex size-full items-center">
        <div
          class="flex h-full grow"
          :data-cse="getAdyenCardFieldId(props.id)"
        ></div>
        <div v-if="slots.icon" class="box-content flex items-center">
          <slot name="icon" />
        </div>
      </div>
    </template>
  </RevInputVirtual>
</template>

<script lang="ts" setup>
import type { VNode } from 'vue'

import { RevInputVirtual } from '@ds/components/InputVirtual'

import type { VirtualCardFieldProps } from '../../../form-common/types/VirtualCardFormState'
import { getAdyenCardFieldId } from '../../helpers/getAdyenCardFieldId'

const props = defineProps<
  {
    label: string
    disabled: boolean
  } & VirtualCardFieldProps
>()

const slots = defineSlots<{
  icon?(): VNode
}>()
</script>
