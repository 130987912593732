import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useScript } from '@unhead/vue'

export const SQUARE_LIBRARY_URL =
  'https://js.squarecdn.com/square-marketplace.js'

export const useSquareLibrary = (method: PaymentMethod) => {
  return useScript(
    'libraryUrl' in method.config && method.config.libraryUrl
      ? method.config.libraryUrl
      : SQUARE_LIBRARY_URL,
  )
}
