<template>
  <RevInfoBlock
    :icon="IconCreditCard"
    title="Testing assistant"
    :variant="RevInfoBlockVariantsEnum.Default"
  >
    <div class="mt-5 grid grid-cols-4 gap-1">
      <RevButton
        v-for="({ label, variant, values }, id) in buttons"
        :key="id"
        :size="SizesEnum.Small"
        type="button"
        :variant="variant || RevButtonVariantsEnum.Secondary"
        @click="handleButtonClick(id, values)"
      >
        {{ label }}
      </RevButton>
      <div class="flex items-center justify-center">
        <RevLink target="_blank" :to="ADYEN_SANDBOX_CARDS_URL">
          More testing cards
        </RevLink>
      </div>
    </div>

    <div
      v-if="showValues"
      class="mt-3 grid grid-cols-[min-content_min-content_auto] items-center gap-4"
    >
      <template v-for="(value, key) in currentValues" :key>
        <RevButton
          :size="SizesEnum.Small"
          :variant="RevButtonVariantsEnum.Secondary"
          @click="copy(value)"
        >
          <IconCopy size="16" />
        </RevButton>
        <pre>{{ key }}:</pre>
        <pre>{{ value }}</pre>
      </template>
    </div>
  </RevInfoBlock>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { RevButton } from '@ds/components/Button'
import {
  VariantsEnum as RevButtonVariantsEnum,
  SizesEnum,
} from '@ds/components/Button/Button.constant'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { VariantsEnum as RevInfoBlockVariantsEnum } from '@ds/components/InfoBlock/InfoBlock.constant'
import { RevLink } from '@ds/components/Link'
import { IconCopy } from '@ds/icons/IconCopy'
import { IconCreditCard } from '@ds/icons/IconCreditCard'
import { useClipboard } from '@vueuse/core'

import type { VirtualCardFieldValues } from '../../types/VirtualCardFormState'

import {
  ADYEN_SANDBOX_CARDS,
  ADYEN_SANDBOX_CARDS_URL,
  type SandboxCardItem,
} from './CreditCardTestingAssistant.constants'

defineProps<{
  showValues: boolean
}>()

const emits = defineEmits<{
  fill: [values: VirtualCardFieldValues]
}>()

const { copy } = useClipboard()

const selectedIndex = ref<string>('CLEAR')

const buttons = computed<
  Record<string, SandboxCardItem & { variant?: RevButtonVariantsEnum }>
>(() => {
  return {
    ...ADYEN_SANDBOX_CARDS.reduce(
      (acc, card) =>
        card.showInTestingAssistant ? { [card.label]: card, ...acc } : acc,
      {},
    ),
    CLEAR: {
      label: 'Clear',
      variant: RevButtonVariantsEnum.SecondaryDestructive,
      values: {
        name: '',
        number: '',
        expiryDate: '',
        securityCode: '',
      },
    } satisfies SandboxCardItem & { variant?: RevButtonVariantsEnum },
  }
})

const currentValues = computed(() => {
  return buttons.value[selectedIndex.value].values
})

const handleButtonClick = (id: string, values: VirtualCardFieldValues) => {
  selectedIndex.value = id
  emits('fill', values)
}
</script>
