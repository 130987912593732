import { PaymentError } from './PaymentError'
import type { PaymentReadableMessage } from './PaymentReadableMessage'

/**
 * Error thrown when a payment setup fails. It notably includes a readable message to display to the user.
 *
 * It is recommended to provide a readable message if an error is specific to the payment adapter.
 * Otherwise, let it undefined and a default message will be used.
 * This default will change depending on the error type (cf. getSetupErrorMessage).
 */
export class PaymentSetupError extends PaymentError {
  public readableMessage?: PaymentReadableMessage

  constructor({
    message,
    readableMessage,
    ...errorOptions
  }: {
    message?: string
    readableMessage?: PaymentReadableMessage
  } & ConstructorParameters<typeof PaymentError>[1]) {
    super(message ?? 'Payment setup error', { ...errorOptions })

    this.readableMessage = readableMessage
  }

  static fromAnyError(
    error: unknown | Error,
    message?: PaymentReadableMessage,
  ) {
    if (error instanceof this) {
      // eslint-disable-next-line no-param-reassign
      error.readableMessage = error.readableMessage ?? message

      return error
    }

    if (error instanceof PaymentError) {
      return new this({
        cause: error,
        message: error.message,
        type: error.type,
        source: error.source,
        readableMessage: message,
      })
    }

    if (error instanceof Error) {
      return new this({
        cause: error,
        message: error.message,
        readableMessage: message,
      })
    }

    return new this({
      cause: error,
    })
  }
}
