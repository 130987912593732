<!--
  `PaymentMethodFormBase` will render the given adapter's form component by passing its props.
  It will also manage the setup and submission lifecycle events of the adapter,
  add tracking, and emit events that are compatible with the PaymentForm component.
-->
<template>
  <PaymentAdapter
    :id="formId"
    :key="`${paymentMethod?.reference}-${adapterKey}`"
    ref="paymentAdapterInstance"
    :adapter="currentAdapter"
    :base-price
    :create-payment="safelyCreatePayment"
    :disabled="disabled ?? false"
    :payment-method
    @setup-error="onSetupError"
    @setup-start="track.setupStart"
    @setup-success="track.setupSuccess"
    @submit-error="onSubmitError"
    @submit-start="onSubmitStart"
    @submit-success="onSubmitSuccess"
  />
</template>

<script lang="ts" setup>
import { computed, onActivated, ref, toRef, toValue } from 'vue'

import type { Price } from '@backmarket/http-api/src/api-models/Price'
import { type PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import type {
  PaymentFormAdapterCreateFunction,
  PaymentFormCreateFunction,
  PaymentSubmitError,
  SetupErrorEvent,
  SubmitErrorEvent,
  SubmitStartEvent,
  SubmitSuccessData,
  SubmitSuccessEvent,
} from '../../../form-common'
import { getRedirection } from '../../../form-common/helpers/getRedirection'
import type { LocalPaymentMethod } from '../../../methods/config/methods.type'
import {
  type TrackingData,
  usePaymentFormEventTracker,
} from '../../composables/usePaymentFormEventTracker'
import { PAYMENT_FORM_ADAPTER_NOT_REQUIRED } from '../../config/adapters'
import { getMethodCorrespondingAdapter } from '../../config/getMethodCorrespondingAdapter'
import { useFraudData } from '../PaymentForm/useFraudData'

import { getSetupErrorMessage } from './PaymentMethodFormBase.utils'
import type { PaymentAdapterInstance } from './components/PaymentAdapter.types'
import PaymentAdapter from './components/PaymentAdapter.vue'
import { useActivated } from './composables/useActivated'

export type PaymentMethodFormBaseProps = {
  paymentMethod: PaymentMethod | LocalPaymentMethod | null
  basePrice: Price
  createPayment: PaymentFormCreateFunction
  trackingData?: Partial<TrackingData>
  disabled?: boolean
}

const props = defineProps<PaymentMethodFormBaseProps>()

export type PaymentMethodFormBaseEmits = {
  'submit-start': [SubmitStartEvent]
  'submit-success': [SubmitSuccessEvent]
  'submit-error': [SubmitErrorEvent]
  'setup-error': [SetupErrorEvent]
}

const emit = defineEmits<PaymentMethodFormBaseEmits>()

const adapterKey = ref(0)
const hasSetupError = ref(false)
const paymentAdapterInstance = ref<PaymentAdapterInstance | null>(null)

const isActivated = useActivated()

const paymentMethod = computed(() => {
  if (!props.paymentMethod) {
    return null
  }
  if ('isLocal' in props.paymentMethod) {
    // 'local' payment methods does not carry much information...
    // But, we still need them to be able to select the correct adapter to show.
    // Having a full-featured `PaymentMethod` object would require local methods
    // to have hardcoded configs (network, group, labels, etc) that will never be used .

    // For now, we trick the form as if there was no payment method selected.
    // except for the selection of the current adapter.

    // Going forward, we may reconsider, either:
    // removing `LocalPaymentMethod` and requiring a full `PaymentMethod` object for buyback methods
    // or accepting `LocalPaymentMethod` objects everywhere a `PaymentMethod` is expected.
    return null
  }

  return props.paymentMethod
})

const isPaymentRequired = computed(
  () => parseFloat(props.basePrice.amount) > 0 || Boolean(paymentMethod.value),
)
const formId = computed(() => {
  return props.paymentMethod?.reference
    ? `payment-method:${props.paymentMethod.reference}`
    : 'payment-method:none'
})

const currentAdapter = computed(() => {
  if (!isPaymentRequired.value) {
    return PAYMENT_FORM_ADAPTER_NOT_REQUIRED
  }

  return getMethodCorrespondingAdapter(props.paymentMethod)
})

const track = usePaymentFormEventTracker(() => ({
  ...props.trackingData,
  selectedPaymentMethod: paymentMethod,
  basePrice: props.basePrice,
}))

const i18n = useI18n()

const { data: fraudData } = useFraudData(toRef(props, 'paymentMethod'))

const safelyCreatePayment: PaymentFormAdapterCreateFunction = async (
  paymentMethodConfig?: Record<string, unknown>,
) => {
  const { paymentId, gatewayReference, gatewayTokenReference, nextAction } =
    await props.createPayment({
      method: props.paymentMethod,
      price: props.basePrice,
      config: paymentMethodConfig,
      fraudData: fraudData.value,
    })

  return {
    paymentId,
    gatewayReference,
    gatewayTokenReference,
    redirection: nextAction ? getRedirection(nextAction, paymentId) : undefined,
  }
}

const onSetupError = (error: PaymentSubmitError) => {
  const message = getSetupErrorMessage(i18n, paymentMethod.value, error)
  track.setupError({ error, message, isActivated: isActivated.value })
  hasSetupError.value = true

  if (isActivated.value) {
    emit('setup-error', {
      paymentMethod: paymentMethod.value,
      message,
      cause: error,
    })
  }
}

const onSubmitStart = () => {
  track.submitStart({ isActivated: isActivated.value })
  if (isActivated.value) {
    emit('submit-start', {
      paymentMethod: paymentMethod.value,
      // TODO: [PAYIN-3634] PaymentForm should proxy-expose adapter's custom loadingMessage
      customLoadingMessage: toValue(
        paymentAdapterInstance.value?.instance?.loadingMessage,
      ),
    })
  }
}
const onSubmitSuccess = (result: SubmitSuccessData) => {
  track.submitSuccess({ ...result, isActivated: isActivated.value })
  if (isActivated.value) {
    emit('submit-success', {
      paymentMethod: paymentMethod.value,
      ...result,
    })
  }
}
const onSubmitError = (error: PaymentSubmitError) => {
  track.submitError({ error, isActivated: isActivated.value })
  if (isActivated.value) {
    emit('submit-error', {
      paymentMethod: paymentMethod.value,
      message: error.readableMessage,
      redirection: error.redirection,
      cause: error,
    })
  }
}

onActivated(() => {
  if (hasSetupError.value) {
    hasSetupError.value = false
    // increment adapter key on setup error, to force reload component when it will be reactivated
    adapterKey.value += 1
  }
})

defineExpose({
  isBusy: computed(() => paymentAdapterInstance.value?.isBusy),
  isDisabled: computed(() => paymentAdapterInstance.value?.isDisabled),
  adapterInstance: computed(() => paymentAdapterInstance.value?.instance),
  formId,
  method: paymentMethod,
})
</script>
