export default {
  issuerLabel: {
    id: 'adyen_hosted_adapter_issuers_label',
    defaultMessage: 'Issuer',
  },
  issuerRequired: {
    id: 'adyen_hosted_adapter_issuers_required',
    defaultMessage: 'Please select an issuer',
  },
}
