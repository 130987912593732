<template>
  <div v-if="shouldRenderChaosBanner" class="px-20">
    <RevInfoBlock
      :icon="IconInfo"
      :title="i18n(translations.chaosTitle)"
      variant="danger"
    >
      <div class="body-2">
        <p>
          {{ i18n(translations.chaosMessage) }}
        </p>
      </div>
    </RevInfoBlock>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconInfo } from '@ds/icons/IconInfo'

import translations from './PaymentChaosBanner.translations'

const i18n = useI18n()

const { countryCode } = useMarketplace().market
const shouldRenderChaosBanner =
  useRuntimeConfig().public.payment.FF_CHAOS_BANNER_IN_COUNTRIES?.includes(
    countryCode,
  )
</script>
