<template><div></div></template>

<script setup lang="ts">
import { h, onMounted, ref } from 'vue'

import { postConfirmHpp } from '@backmarket/http-api/src/api-specs-payment/payment/payment'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import type {
  GooglePayPaymentData,
  GooglePayPaymentsError,
} from '../../../../types/google-pay'
import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterExpose,
  type PaymentFormAdapterProps,
  PaymentSetupError,
  PaymentSubmitError,
} from '../../../form-common'
import { getRedirection } from '../../../form-common/helpers/getRedirection'
import { useGooglePay } from '../../composables/useGooglePay'

import translations from './AdyenGooglePayAdapter.translations'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const googlePay = useGooglePay(() => props.paymentMethod)
const marketplace = useMarketplace()
const i18n = useI18n()

const googlePayButtonContainer = ref()

async function createPayment(paymentData: GooglePayPaymentData) {
  try {
    const { paymentId } = await props.createPayment({})

    const response = await $httpFetch(postConfirmHpp, {
      pathParams: { paymentId },
      body: {
        gatewayToken: paymentData.paymentMethodData.tokenizationData.token,
        browserInfo: googlePay.collectBrowserInfo(),
      },
    })

    // Redirect to payment result OR nextAction.
    const redirection = getRedirection(response.nextAction, paymentId)

    emit('submit-success', { paymentId, redirection })
  } catch (err) {
    emit('submit-error', PaymentSubmitError.fromAnyError(err))
  }
}

function getPaymentErrorFromGooglePayPaymentsError(
  error: GooglePayPaymentsError,
) {
  // User canceled the payment with google pay (e.g. by closing the payment sheet)
  if (error.statusCode === 'CANCELED') {
    return new PaymentSubmitError({
      type: '/errors/payment/google-pay-user-canceled',
      message: error.statusMessage,
      cause: error,
    })
  }

  let readableMessage: PaymentSubmitError['readableMessage']

  // Handle known error codes
  if (
    error.statusCode === 'BUYER_ACCOUNT_ERROR' ||
    error.statusCode === 'DEVELOPER_ERROR' ||
    error.statusCode === 'MERCHANT_ACCOUNT_ERROR' ||
    error.statusCode === 'INTERNAL_ERROR'
  ) {
    readableMessage = {
      title: i18n(translations[`${error.statusCode}_title`]),
      description: i18n(translations[`${error.statusCode}_description`]),
    }
  } else {
    readableMessage = {
      title: i18n(translations.defaultErrorTitle),
      description: i18n(translations.defaultErrorDescription),
    }
  }

  return new PaymentSubmitError({
    type: '/errors/payment/unexpected/google-pay-error',
    message: error.statusMessage,
    cause: error,
    readableMessage,
  })
}

async function onGooglePaymentButtonClicked() {
  emit('submit-start')

  const paymentDataRequest = googlePay.getPaymentDataRequest({
    currencyCode: props.basePrice.currency,
    countryCode: marketplace.market.countryCode,
    totalPrice: props.basePrice.amount,
    totalPriceStatus: 'FINAL',
  })

  try {
    const paymentsClient = await googlePay.getPaymentsClient()
    const paymentData = await paymentsClient.loadPaymentData(paymentDataRequest)

    return createPayment(paymentData)
  } catch (err) {
    emit(
      'submit-error',
      getPaymentErrorFromGooglePayPaymentsError(err as GooglePayPaymentsError),
    )

    return undefined
  }
}

async function addGooglePayButton() {
  const button = (await googlePay.getPaymentsClient()).createButton({
    onClick: onGooglePaymentButtonClicked,
    buttonSizeMode: 'fill',
  })

  googlePayButtonContainer.value.appendChild(button)
}

async function prefetchGooglePaymentData() {
  const paymentDataRequest = googlePay.getPaymentDataRequest({
    currencyCode: props.basePrice.currency,
    countryCode: marketplace.market.countryCode,
    totalPrice: props.basePrice.amount,
    totalPriceStatus: 'ESTIMATED',
  })

  const paymentsClient = await googlePay.getPaymentsClient()
  paymentsClient.prefetchPaymentData(paymentDataRequest)
}

onMounted(async () => {
  emit('setup-start')

  try {
    await googlePay.isReadyWithAPaymentMethodPresent(false)
    await Promise.all([addGooglePayButton(), prefetchGooglePaymentData()])

    emit('setup-success')
  } catch (err) {
    emit('setup-error', PaymentSetupError.fromAnyError(err))
  }
})

defineExpose<PaymentFormAdapterExpose>({
  submitButton: {
    is: {
      setup() {
        return () =>
          h('div', {
            ref: googlePayButtonContainer,
            class: 'h-48 w-full min-w-80 md:w-auto',
          })
      },
    },
  },
})
</script>
