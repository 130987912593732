<template>
  <BannerBase
    background="bg-[#8bbc06]/10"
    class="min-h-224 md:min-h-240"
    :is-busy
  >
    <div :id="uniqueId" class="size-full"></div>
  </BannerBase>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'

import type { Price } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { v4 as uuid } from 'uuid'

import { isValidOneyPaymentMethodConfig } from '../../../form-common/helpers/isValidConfig'
import {
  type OneyOptions,
  useOneyLibrary,
} from '../../composables/useOneyLibrary'
import BannerBase from '../BannerBase/BannerBase.vue'

const props = defineProps<{
  paymentMethod: PaymentMethod
  basePrice: Price
}>()

const isBusy = ref(true)
const marketplace = useMarketplace()

const uniqueId = `oney-${uuid()}`

const { onLoaded } = useOneyLibrary(props.paymentMethod)

const updateOneyWidget = () => {
  if (typeof window.loadOneyWidget !== 'function') {
    // Retry when the script is loaded
    onLoaded(updateOneyWidget)

    return
  }

  const { config } = props.paymentMethod
  if (!isValidOneyPaymentMethodConfig(config)) {
    return
  }

  const options: OneyOptions = {
    country: marketplace.market.countryCode,
    language: marketplace.market.countryCode,
    merchant_guid: config.merchantGuid,
    payment_amount: props.basePrice.amount,
    filter_by: 'filters',
    filters: [
      {
        payment_method: 'bnpl',
        payment_method_type: 'split',
        is_free: 'false',
        with_down_payment: 'true',
        payment_mode: props.paymentMethod.bmCode.replace('oney', ''),
      },
    ],
    hide_logo: false,
    checkout_placeholder: `#${uniqueId}`,
  }

  window.loadOneyWidget(() => {
    window.oneyMerchantApp.loadCheckoutSection({ options })
    isBusy.value = false
  })
}

watch([() => props.paymentMethod, () => props.basePrice], updateOneyWidget)
onMounted(updateOneyWidget)
</script>

<style>
/* Customize the oney loading */
.oney-loadingCheckout {
  display: flex;
  justify-content: center;
}
</style>
