import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

import { ProcessOutExceptionCodes } from '../types/ProcessOutExceptionCodes'

export default {
  errorTitle: {
    id: 'funnel_payment_page_payment_error_title',
    defaultMessage: 'Payment has failed',
  },
  errorDescription: {
    id: 'funnel_payment_page_payment_error_message',
    defaultMessage:
      "We're sorry, but your payment didn't work. Here is the error message that our secure payment system sent us: {reason}.",
  },
  [ProcessOutExceptionCodes.CUSTOMER_POPUP_BLOCKED]: {
    id: 'processout_sdk_error_customer_popup_blocked',
    defaultMessage:
      'Please allow pop-ups, in order to continue with your payment flow.',
  },

  // Name
  [ProcessOutExceptionCodes.CARD_INVALID_NAME]: {
    id: 'processout_sdk_error_card_invalid_name',
    defaultMessage: 'This card holder seems to be invalid',
  },

  // Number
  [ProcessOutExceptionCodes.CARD_MISSING_NUMBER]: {
    id: 'processout_sdk_error_missing_number',
    defaultMessage: 'Card number is required',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_NUMBER]: {
    id: 'processout_sdk_error_card_invalid_number',
    defaultMessage: 'This card number is invalid',
  },

  // Date
  [ProcessOutExceptionCodes.CARD_MISSING_EXPIRY]: {
    id: 'processout_sdk_error_card_missing_expiry',
    defaultMessage: 'Date is required',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_EXPIRY_DATE]: {
    id: 'processout_sdk_error_card_invalid_expiry_date',
    defaultMessage: 'This date is invalid',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_DATE]: {
    id: 'processout_sdk_error_card_invalid_expiry_date',
    defaultMessage: 'This date is invalid',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_EXPIRY_MONTH]: {
    id: 'processout_sdk_error_card_invalid_expiry_month',
    defaultMessage: 'Expiry month is invalid',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_MONTH]: {
    id: 'processout_sdk_error_card_invalid_expiry_month',
    defaultMessage: 'Expiry month is invalid',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_EXPIRY_YEAR]: {
    id: 'processout_sdk_error_card_invalid_expiry_year',
    defaultMessage: 'Expiry year is invalid',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_YEAR]: {
    id: 'processout_sdk_error_card_invalid_expiry_year',
    defaultMessage: 'Expiry year is invalid',
  },

  // Security code
  [ProcessOutExceptionCodes.CARD_MISSING_CVC]: {
    id: 'processout_sdk_error_card_missing_cvc',
    defaultMessage: 'CVV is missing',
  },
  [ProcessOutExceptionCodes.CARD_FAILED_CVC]: {
    id: 'processout_sdk_error_card_failed_cvc',
    defaultMessage: 'This CVV is invalid',
  },
  [ProcessOutExceptionCodes.CARD_INVALID_CVC]: {
    id: 'processout_sdk_error_card_invalid_cvc',
    defaultMessage: 'This CVV is invalid',
  },
} satisfies Record<string, I18nDefinition> as Record<string, I18nDefinition>
